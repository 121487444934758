import { useCallback, useState } from 'react';
import AppContext from './appContext';


let preloadUser = localStorage.getItem('user')
if (preloadUser) preloadUser = JSON.parse(preloadUser)
else preloadUser = {token: '', permissions: {backoffice: false}}



function AppProvider({ children }) {
  
    const [user, setUser] = useState(preloadUser);
    const [token, setToken] = useState(preloadUser.token);

    const updateToken = useCallback( (token) => { setToken(token) }, []);

    const updateUser = useCallback( (user) => { 

        setUser(user)
        localStorage.setItem("user", JSON.stringify(user));
    }, []);

    const value = { state: { user, token }, actions: { updateToken, updateUser } };

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  )
}
export default AppProvider