import DataTableComponent from '../components/DataTableComponent'
import columnsPriceRules from '../utils/price-rules-data'
import { useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { getDataApi } from '../services/apiService'



export const LANG = 'es'
export const MONTH_NAMES_LONG = Array.from({length: 12}, (_, month) => new Date(2024, month, 3).toLocaleDateString(LANG, {month: 'long'}))
export const MONTH_NAMES_SHORT = Array.from({length: 12}, (_, month) => new Date(2024, month, 3).toLocaleDateString(LANG, {month: 'short'}))
export const DAY_NAMES_LONG = Array.from({length: 7}, (_, offset) => new Date(2024, 0, 7 + offset).toLocaleDateString(LANG, {weekday: 'long'}))
export const DAY_NAMES_SHORT = Array.from({length: 7}, (_, offset) => new Date(2024, 0, 7 + offset).toLocaleDateString(LANG, {weekday: 'short'}))


const parseNum = (format, num) => {

    let parsedNum = ''

    switch (format) {
        case '2-digit':
            parsedNum = `${num < 0 ? '-' : ''}${num < 10 ? '0' : ''}${Math.floor(num)}`
        break;
        case 'EUR':
            parsedNum = new Intl.NumberFormat('es-ES', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2
            }).format(num);
        break;        
        case 'percent':
            parsedNum = new Intl.NumberFormat('es-ES', {
                style: 'percent',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2
            }).format(num);
        break;
        case 'es':
            parsedNum = new Intl.NumberFormat('es-ES', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2
            }).format(num);
        break;
        case 'discount':
            parsedNum = Math.abs(num) < 1 ? parseNum('percent', -1 * num) : parseNum('EUR', -1 * num)
        break;
        default: parsedNum = String(num);
    }

    return parsedNum

}


const parseDate = (format, date, utc) => {

    try {

        const dateObj = new Date(date)
        let parsedDate = ''
        if (isNaN(dateObj.getTime())) throw new Error(`Not possible to build a date object with ${date}`)

        const WEEK_DAY = utc ? dateObj.getUTCDay() : dateObj.getDay()
        const DATE = utc ? dateObj.getUTCDate() : dateObj.getDate()
        const MONTH = utc ? dateObj.getUTCMonth() : dateObj.getMonth()
        const YEAR = utc ? dateObj.getUTCFullYear() : dateObj.getFullYear()
        const HOURS = utc ? dateObj.getUTCHours() : dateObj.getHours()
        const MINUTES = utc ? dateObj.getUTCMinutes() : dateObj.getMinutes()

        switch (format) {

            case 'Mon':
                parsedDate = DAY_NAMES_SHORT[WEEK_DAY]
            break;
            case 'd':
                parsedDate = String(DATE)
            break;
            case 'November yyyy':
                parsedDate = `${MONTH_NAMES_LONG[MONTH]} ${YEAR}`
            break;
            case 'Nov':
                parsedDate = MONTH_NAMES_SHORT[MONTH]
            break;
            case 'd Nov yyyy':
                parsedDate = `${DATE} ${MONTH_NAMES_SHORT[MONTH]} ${YEAR}`
            break;
            case 'Mon d November yyyy':
                parsedDate = `${DAY_NAMES_SHORT[WEEK_DAY]} ${DATE} ${MONTH_NAMES_LONG[MONTH]} ${YEAR}`
            break;
            case 'd Nov \'yy':
                parsedDate = `${DATE} ${MONTH_NAMES_SHORT[MONTH]} '${String(YEAR).substring(2)}`
            break;
            case 'yyyy-mm-dd': 
            parsedDate = `${YEAR}-${parseNum('2-digit', MONTH + 1)}-${parseNum('2-digit', DATE)}`
            break;
            case 'hh:mm': 
            parsedDate = `${parseNum('2-digit', HOURS)}:${parseNum('2-digit', MINUTES)}`
            break;
            default: break;
        }

        return parsedDate

    } catch (error) { 
        return 'Invalid Date'
    }
}

export const findPriceRuleMatch = (criteria, priceRules)  => {

    let match = null

    let now = new Date()
    let bookingDate = new Date(criteria.bookingDate)

    const BOOKING_DATE_ISO = parseDate('yyyy-mm-dd', bookingDate)
    const WEEK_DAY = bookingDate.getDay()
    const HOUR = now.getHours()
    const DAYS_TILL_EVENT = Math.ceil((bookingDate.getTime() - now.getTime()) / 1000 / 60 / 60 / 24)
    const SHIFT_ID = criteria.shiftId ?? null
    const EVENT_ID = criteria.eventId ?? null
    const PRODUCT_ID = criteria.productId ?? null

    for (let priceRule of priceRules) {

        const safe_availableFrom = new Date(parseDate('yyyy-mm-dd', priceRule.availableFrom, true))
        const safe_availableTo = new Date(parseDate('yyyy-mm-dd', priceRule.availableTo, true))

        if (safe_availableFrom.getTime() > bookingDate.getTime()) {
			continue
		}
		if (safe_availableTo.getTime() < bookingDate.getTime()) {
			continue
		}
        // if (priceRule.web === 0) {
        //     continue
        // }
        // if (priceRule.venueId !== 0 && priceRule.venueId !== rumba.getVenueId()) {
        //     continue
        // }
		if (priceRule.rules.noWeekDays && priceRule.rules.noWeekDays.indexOf(WEEK_DAY) !== -1) {
			continue
		}
		if (DAYS_TILL_EVENT > priceRule.maxDaysBefore || DAYS_TILL_EVENT < priceRule.daysInAdvance) {
			continue
		}
		if (DAYS_TILL_EVENT === priceRule.daysInAdvance && priceRule.maxHour < HOUR) {
			continue
		}
		if (SHIFT_ID && priceRule.rules.yesShiftIds && priceRule.rules.yesShiftIds.indexOf(SHIFT_ID) === -1) {
			continue
		}
		if (EVENT_ID && priceRule.rules.yesEventIds && priceRule.rules.yesEventIds.indexOf(EVENT_ID) === -1) {
			continue
		}
		if (PRODUCT_ID && priceRule.rules.yesProductIds && priceRule.rules.yesProductIds.indexOf(PRODUCT_ID) === -1) {
			continue
		}
        if (priceRule.rules.noDts) {

            const ruleNotFulfilled = priceRule.rules.noDts.some(toExclude => {
                // v -> yyyy-mm-dd || yyyy-mm-dd...yyyy-mm-dd
                const datesToExclude = toExclude.split('...')
                if (datesToExclude.length===2) return (
                    new Date(BOOKING_DATE_ISO).getTime() >= new Date(datesToExclude[0]).getTime() && 
                    new Date(BOOKING_DATE_ISO).getTime() <= new Date(datesToExclude[1]).getTime()
                )
                else return toExclude === BOOKING_DATE_ISO
            }) 
            
			if (ruleNotFulfilled) continue
		}

        match = {
            discount: priceRule.discount,
            promotionalText: priceRule.promotionalText,
            visible: priceRule.visible
        }
        break
    }

    return match
}

const PriceRulesPage = () => {

    const [data, setData] = useState([])

    const navigate = useNavigate()
    const handleAddNew = () => navigate('/priceRule/edit/0')

    const getPriceRules = async () => {
        let res = await getDataApi(0, 'priceRule')
        if (res.result==='ok') {
            setData(res.data)
            // console.log(findPriceRuleMatch({bookingDate: '2024-12-31', eventId: 29789, shiftId: 8, productId: 638}, res.data))
        }
        else setData([])
    }

    useEffect(() => {
        getPriceRules()
    }, [])

    return (

        <div className='card'>
            <div className='card-body'>
            <DataTableComponent
                    title="Reglas de fijado de precios"
                    columns={columnsPriceRules}
                    data={data}
                    model="priceRule"
                    sercheable={false}
                    RightSideComponent={() => {
                        return (
                            <button type="button" className="btn rmb-btn-primary maxh-37" onClick={handleAddNew}>
								Nueva Regla
							</button>
                        )
                    }}
                />
            </div>
        </div>

    )
}

export default PriceRulesPage