import { getTrackings } from "../services/apiService";
import DataTableComponent from '../components/DataTableComponent'
import { 
    defaultFilters,
    columnsComputedTracking,
    config,
    dashBoard,
    sumTrackings,
} from "../utils/tracking-data.js"
import { useState, useEffect, useRef, useCallback } from 'react'
import Filters from '../components/filters/filters.js'
import { Chart } from "../components/Chart.js";

let firstTime = true

const TrackingPage = () => {

    const groupingBy = useRef('-')
    const comparing = useRef(false)

    const [loading, setLoading] = useState(false)
    const [groupedData, setGroupedData] = useState({
        current: [],
        last: []
    })
    const [data, setData] = useState({
        venueId: [0, 0],
		sessions: [0, 0],
		pages: [0, 0],
		sales: [0, 0],
		bookings: [0, 0],
		pax: [0, 0],
		startedBookings: [0, 0],
		dateCheckouts: [0, 0],
		shiftCheckouts: [0, 0],
		ticketCheckouts: [0, 0],
		methodCheckouts: [0, 0],
		paymentCheckouts: [0, 0],
        funnel: []
    })
    const [filters, setFilters] = useState(defaultFilters)
    const [preferences, setPreferences] = useState({autoFilters: 0})
    const [filteredColumns, setFilteredColumns] = useState(columnsComputedTracking)
    const [groupByOptions, setGroupByOptions] = useState([
        {name: 'Ninguno', value: ''},
        {name: 'Automático', value: '-'},
        {name: 'Source', value: 'source'},
        {name: 'Campaña', value: 'campaign'},
        {name: 'Medio', value: 'medium'},
        {name: 'Grupo', value: 'group'},
        {name: 'Venue', value: 'venueId'},
    ])

    const getTrackingData = useCallback(async () => {

        setLoading(true)
        const gb = groupingBy.current === '-' ?
            filters.venueId === 0 ? 'venueId' :
            filters.group.length ? 'venueId' : 
		    filters.campaign.length ? 'group' :
		    filters.medium.length ? 'campaign' :
		    filters.source.length ? 'medium' :
		    'source' : groupingBy.current


        setFilteredColumns(() => columnsComputedTracking.filter((column) => column.name === gb && column.isGrouped || !column.isGrouped))

        let res = await getTrackings(filters, gb)
        if (!res.data) return

        const {current, last} = res.data
        setGroupedData({current, last})

        const currentTotal = sumTrackings(JSON.parse(JSON.stringify(current)))
        const lastTotal = sumTrackings(JSON.parse(JSON.stringify(last)))
        
        setData(() => {

            const totals = Object.keys(currentTotal).reduce((newState, key) => {

                newState[key] = [currentTotal[key], lastTotal[key]]
                return newState

            }, {})

            const funnel = [
                currentTotal.sessions,
                currentTotal.startedBookings,
                currentTotal.dateCheckouts,
                currentTotal.shiftCheckouts,
                currentTotal.ticketCheckouts,
                currentTotal.methodCheckouts,
                currentTotal.paymentCheckouts,
            ]

            return {...totals, funnel}

        })
        
        setLoading(false)

    }, [filters])

    useEffect(()=>{
        
        if (firstTime) {
            firstTime = false
            return
        }

        if (filters.dateLast[0]!=='' || filters.dateLast[1]!=='') comparing.current = true
        else comparing.current = false

        getTrackingData()
    },[filters, preferences])

    return (
        <div className='card trackings_page'>
            <div className='card-body'>
                <div className='tracking_page_header'><p className='page_title'>TRACKINGS</p></div>
                <div className="controls">
                    <Filters 
                        config={config}
                        onFilterChange={(e) => setFilters(e)}
                        onPreferenceChange={(e) => setPreferences(e)}
                        disable={loading}
                    />
                </div>
                <hr />
                <Chart
                    id={'trackings-chart'}
                    data={JSON.parse(JSON.stringify(data.funnel))}
                    labels={["Visitantes","Calendario","Fecha","Pase","Ticket","Checkout","Pago"]}
                />
                <div className="chart-values-wrapper" style={{'--values': data.funnel.length}}>
                    <div className="chart-values" style={{'--values': data.funnel.length}}>
                    {   
                        data.funnel.map((value, index, array) => {

                            const diff = (array[index - 1] ?? value) - value

                            return (
                                <div className="chart-value-container">
                                    {/* <span className="chart-value">{diff}</span> */}
                                    <span className="chart-value">{value}</span>
                                </div>
                            )

                        })
                    }
                    </div>
                </div>
                <hr />
                <div className="trackings_container">
                    { loading && (<div className="loader_container"><span className="loader"></span></div>)}
                    { dashBoard.map(({name, icon, calc, parse}, index) => {

                        const [value, diff] = calc(data)

                        return (
                            <div className="trackings" key={index}>
                                <p className="comparation">
                                    <span>{parse(value)}</span>
                                </p>
                                <p className="title"><span className={`ti ${icon}`}></span>{name}</p>
                            </div>
                        )
                    })}
                </div>
                <DataTableComponent
                    title="Desglose trackings"
                    columns={filteredColumns}
                    data={groupedData.current}
                    loading={loading}
                    model="trackings"
                    noPagination
                    sercheable={false}
                    selectConfig={{
                        name: 'trackings',
                        selectId: 'slct-trackings',
                        options: groupByOptions,
                        setOptions: setGroupByOptions,
                        onInput: (e) => {
                            groupingBy.current = e.target.value
                            getTrackingData()
                        },
                        showCurrentValue: true,
                        selectAllOption: false,
                        defaultValue: '-',
                        autoHide: true,
                        onlyOne: true,
                        toggleSelects: true
                    }}
                />
            </div>
        </div>
    )
}

export default TrackingPage