import moment from 'moment'
import { getOptions } from '../services/apiService'
import { venueNames } from './bookings-data'
import 'moment/locale/es'
moment.locale('es')
const numberWithSpaces = (x, f = 2) => {
	const n = String(Number(x || 0).toFixed(f)).split('.')
	return n[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.') + (f === 0 ? '' : ',' + n.slice(1))
}

export const dashBoard = [
	{
		name: 'Visitantes',
		icon: 'ti-eye',
		key: '',
		calc: ({ sessions }) => {

			const [current, last] = sessions

			return [current, current - last]
		},
		parse: (value) => numberWithSpaces(value, 0)
	},
	{
		name: 'Calendario',
		icon: 'ti-calendar',
		key: '',
		calc: ({ startedBookings }) => {

			const [current, last] = startedBookings

			return [current, current - last]
		},
		parse: (value) => numberWithSpaces(value, 0)
	},
	{
		name: 'Reservas',
		icon: 'ti-friends',
		key: '',
		calc: ({ bookings }) => {

			const [current, last] = bookings

			return [current, current - last]
		},
		parse: (value) => numberWithSpaces(value, 0)
	},
	{
		name: 'Venta total',
		icon: 'ti-coins',
		key: '',
		calc: ({ sales }) => {

			const [current, last] = sales

			return [current, current - last]
		},
		parse: (value) => numberWithSpaces(value) + '€'
	},
	{
		name: 'Personas',
		icon: 'ti-user',
		key: '',
		calc: ({ pax }) => {

			const [current, last] = pax

			return [current, current - last]
		},
		parse: (value) => numberWithSpaces(value, 0)
	},
	{
		name: 'Páginas / visitante',
		icon: 'ti-file',
		key: '',
		calc: ({ sessions, pages }) => {

			const [currentSessions, lastSessions] = sessions
			const [currentPages, lastPages] = pages

			const current = (currentSessions + currentPages) / currentSessions
			const last = (lastSessions + lastPages) / lastSessions

			return [current, current - last]
		},
		parse: (value) => numberWithSpaces(value, 1)
	},
	{
		name: 'Ratio calendario',
		icon: 'ti-graph',
		key: '',
		calc: ({ startedBookings, bookings }) => {

			const [currentStartedBookings, lastStartedBookings] = startedBookings
			const [currentBookings, lastBookings] = bookings

			const current = (currentBookings / currentStartedBookings) * 100
			const last = (lastBookings / lastStartedBookings) * 100

			return [current, current - last]
		},
		parse: (value) => numberWithSpaces(value, 2) + '%'
	},
	{
		name: 'Ratio de conv.',
		icon: 'ti-graph',
		key: '',
		calc: ({ sessions, bookings }) => {

			const [currentSessions, lastSessions] = sessions
			const [currentBookings, lastBookings] = bookings

			const current = (currentBookings / currentSessions) * 100
			const last = (lastBookings / lastSessions) * 100

			return [current, current - last]
		},
		parse: (value) => numberWithSpaces(value, 2) + '%'
	},
	{
		name: 'Ticket medio',
		icon: 'ti-shopping-cart',
		key: '',
		calc: ({ sales, bookings }) => {

			const [currentSales, lastSales] = sales
			const [cuurentBookings, lastBookings] = bookings

			const current = currentSales / cuurentBookings
			const last = lastSales / lastBookings

			return [current, current - last]
		},
		parse: (value) => numberWithSpaces(value) + '€'
	},
	{
		name: 'Ticket medio x pax',
		icon: 'ti-ticket',
		key: '',
		calc: ({ sales, pax }) => {

			const [currentSales, lastSales] = sales
			const [currentPax, lastPax] = pax

			const current = currentSales / currentPax
			const last = lastSales / lastPax

			return [current, current - last]
		},
		parse: (value) => numberWithSpaces(value) + '€'
	},
]

const columnsComputedTracking = [
	{
		name: 'venueId',
		isGrouped: true,
		cell: (props) => <strong>{venueNames[props.venueId] ?? 'TOTAL'}</strong>
	},
	{
		name: 'source',
		isGrouped: true,
		cell: (row) => row.source === '' ? 'Ninguno' : row.source ?? '-',
		sortable: true,
	},
	{
		name: 'medium',
		isGrouped: true,
		cell: (row) => row.medium === '' ? 'Ninguno' : row.medium ?? '-',
		sortable: true,
	},
	{
		name: 'campaign',
		isGrouped: true,
		cell: (row) => row.campaign === '' ? 'Ninguno' : row.campaign ?? '-',
		sortable: true,
	},
	{
		name: 'group',
		isGrouped: true,
		cell: (row) => row.group === '' ? 'Ninguno' : row.group ?? '-',
		sortable: true,
	},
	{
		name: 'Visitantes',
		cell: (row) => numberWithSpaces(row.sessions, 0),
		sortable: true,
		width: '100px',
	},
	{
		name: 'Páginas / visitante',
		cell: (row) => numberWithSpaces(row.pages / Math.max(1, row.sessions), 1),
		sortable: true,
		width: '100px',
	},
	{
		name: 'Calendario',
		cell: (row) => numberWithSpaces(row.startedBookings, 0),
		sortable: true,
		width: '100px',
	},
	{
		name: 'Ratio calendario.',
		cell: (row) => numberWithSpaces((row.bookings / Math.max(1, row.startedBookings)) * 100, 2) + '%',
		sortable: true,
		width: '100px',
	},
	{
		name: 'Reservas',
		cell: (row) => numberWithSpaces(row.bookings, 0),
		sortable: true,
		width: '100px',
	},
	{
		name: 'Ratio de Conv.',
		cell: (row) => numberWithSpaces((row.bookings / Math.max(1, row.sessions)) * 100, 2) + '%',
		sortable: true,
		width: '100px',
	},
	{
		name: 'Venta total',
		cell: (row) => numberWithSpaces(row.sales) + '€',
		sortable: true,
		width: '100px',
	},
	{
		name: 'Ticket medio',
		cell: (row) => numberWithSpaces(row.sales / Math.max(1, row.pax)) + '€',
		sortable: true,
		width: '100px',
	},
]
function getMonday(date) {
	let d = date ? new Date(date) : new Date()
	const day = d.getDay()
	const diff = d.getDate() - day + (day === 0 ? -6 : 1)
	return new Date(d.setDate(diff))
}
const getDefaultDates = (mode, dts) => {
	const [dt1, dt2] = dts.map((dt) => new Date(dt))

	return mode === 1
		? [parseDate(new Date(dt1.getFullYear(), 0, 1), 0), parseDate(new Date(dt1.getFullYear(), 11, 31), 1)]
		: mode === 2
		? [
				parseDate(new Date(dt1.getFullYear(), new Date(dt1).getMonth(), 1), 0),
				parseDate(new Date(dt1.getFullYear(), new Date(dt1).getMonth() + 1, 0), 1),
		  ]
		: mode === 3
		? [
				parseDate(new Date(dt1.getFullYear(), new Date(dt1).getMonth(), 1), 0),
				parseDate(new Date(dt1.getFullYear(), new Date(dt1).getMonth(), 1), 1),
		  ]
		: [parseDate(getMonday(dt1), 0), parseDate(new Date(getMonday(dt1).setDate(getMonday(dt1).getDate() + 6)), 1)]
}
const goBackward = (mode, dts) => {
	const [dt1, dt2] = dts.map((dt) => new Date(dt))

	return mode === 1
		? [parseDate(new Date(dt1.getFullYear() - 1, 0, 1), 0), parseDate(new Date(dt2.getFullYear() - 1, 11, 31), 1)]
		: mode === 2
		? [
				parseDate(new Date(dt1.getFullYear(), dt1.getMonth() - 1, 1), 0),
				parseDate(new Date(dt2.getFullYear(), dt2.getMonth(), 0), 1),
		  ]
		: mode === 3
		? [
				parseDate(new Date(dt1.getFullYear(), dt1.getMonth(), dt1.getDate() - 1), 0),
				parseDate(new Date(dt1.getFullYear(), dt1.getMonth(), dt1.getDate() - 1), 1),
		  ]
		: [
				parseDate(new Date(dt1.getFullYear(), dt1.getMonth(), dt1.getDate() - 7), 0),
				parseDate(new Date(dt1.getFullYear(), dt1.getMonth(), dt1.getDate() - 1), 1),
		  ]
}
const goForward = (mode, dts) => {
	const [dt1, dt2] = dts.map((dt) => new Date(dt))

	return mode === 1
		? [parseDate(new Date(dt1.getFullYear() + 1, 0, 1), 0), parseDate(new Date(dt2.getFullYear() + 1, 11, 31), 1)]
		: mode === 2
		? [
				parseDate(new Date(dt1.getFullYear(), dt1.getMonth() + 1, 1), 0),
				parseDate(new Date(dt2.getFullYear(), dt2.getMonth() + 2, 0), 1),
		  ]
		: mode === 3
		? [
				parseDate(new Date(dt1.getFullYear(), dt1.getMonth(), dt1.getDate() + 1), 0),
				parseDate(new Date(dt1.getFullYear(), dt1.getMonth(), dt1.getDate() + 1), 1),
		  ]
		: [
				parseDate(new Date(dt2.getFullYear(), dt2.getMonth(), dt2.getDate() + 1), 0),
				parseDate(new Date(dt2.getFullYear(), dt2.getMonth(), dt2.getDate() + 7), 1),
		  ]
}

const parseDate = (d = Date.prototype, i, time = true) => {
	let l = new Date(d.getTime() - d.getTimezoneOffset() * 60000).toISOString().substring(0, 10)
	if (!time) return l
	l += i ? 'T23:59' : 'T00:00'
	l = l.replace('T', ' ')
	return l
}

const defaultFilters = {
	venueId: 0,
	source: [],
	medium: [],
	campaign: [],
	group: [],
	date: ['', ''],
	dateLast: ['','']
}

const config = {
	page: 'trackingsPage',
	filtersInterface: defaultFilters,
	localStorageItem: 'trackings-filters',
	preferencesInterface: { autoFilters: 0 },
	rows: [
		[
			{
				id: 'slct-tablao',
				key: 'venueId',
				type: 'select',
				role: 'filter',
				label: 'Tablao',
				preferences: ['onlyOne', 'autoHide', 'showCurrentValue', 'toggleSelects'],
				optionsAndTags: () => [
					[
						{ value: 0, name: 'Todos' },
						{ value: 1, name: 'Cardamomo' },
						{ value: 2, name: '1911' },
					],
					[],
				],
			},
			{ key: 'date', type: 'date', label: 'Creación', role: 'filter' },
			{ key: 'dateLast', type: 'date', label: 'Comparación', role: 'filter' },
		],
		[
			{
				id: 'slct-source',
				key: 'source',
				type: 'select',
				role: 'filter',
				placeholder: 'Source',
				preferences: ['selectAllOption', 'showCheckedValuesTag', 'multiSelect', 'toggleSelects', 'searchable'],
				optionsAndTags: async () => {
					let options = await getOptions('source')
					return mapOptions('source', options)
				},
			},
			{
				id: 'slct-medium',
				key: 'medium',
				type: 'select',
				role: 'filter',
				placeholder: 'Medium',
				preferences: ['selectAllOption', 'showCheckedValuesTag', 'multiSelect', 'toggleSelects', 'searchable'],
				optionsAndTags: async () => {
					let options = await getOptions('medium')
					return mapOptions('medium', options)
				},
			},
			{
				id: 'slct-campaign',
				key: 'campaign',
				type: 'select',
				role: 'filter',
				placeholder: 'campaign',
				preferences: ['selectAllOption', 'showCheckedValuesTag', 'multiSelect', 'toggleSelects', 'searchable'],
				optionsAndTags: async () => {
					let options = await getOptions('campaign')
					return mapOptions('campaign', options)
				},
			},
			{
				id: 'slct-group',
				key: 'group',
				type: 'select',
				role: 'filter',
				placeholder: 'group',
				preferences: ['selectAllOption', 'showCheckedValuesTag', 'multiSelect', 'toggleSelects', 'searchable'],
				optionsAndTags: async () => {
					let options = await getOptions('group')
					return mapOptions('group', options)
				},
			},
		],
		[{ key: 'autoFilters' }, [{ key: 'clearFilters' }, { key: 'applyFilters' }]],
	],
}

const sumTrackings = (arr) => {

	let acc = {
		venueId: 0,
		sessions: 0,
		pages: 0,
		sales: 0,
		bookings: 0,
		pax: 0,
		startedBookings: 0,
		dateCheckouts: 0,
		shiftCheckouts: 0,
		ticketCheckouts: 0,
		methodCheckouts: 0,
		paymentCheckouts: 0,
	}

    return arr.reduce((total, obj) => {
        for (let key of Object.keys(acc)) {
			if (obj[key]) total[key] += Number(obj[key])
		}
		return total
    }, acc)

}

const mapOptions = (type, opts) => {

	let options = Array.isArray(opts[type]) ? opts[type] : [opts[type]]
	let types = ['campaign', 'group', 'source', 'medium'].filter(t => t !== type)
	let result = []
	for (let opt of  options) {

		if (opt[type] === null) continue

		let name = String(opt[type])
			.replace(/%20/gi, ' ') // Reemplaza %20 por espacio
			.replace(/%2B/gi, '+') // Reemplaza %2B por +
			.replace(/%2F/gi, '/') // Reemplaza %2F por /
			.replace(/%3A/gi, ':') // Reemplaza %3A por :
			.replace(/%3F/gi, '?') // Reemplaza %3F por ?
			.replace(/%3D/gi, '=') // Reemplaza %3D por =
		
		result.push({
			name: opt[type] === '' ? 'Ninguno' : name,
			value: opt[type] === '' ? 'empty' : opt[type],
			dependencies: {
				[types[0]]: String(opt[types[0]]).split(',').map(v => v==='' ? 'empty' : v),
				[types[1]]: String(opt[types[1]]).split(',').map(v => v==='' ? 'empty' : v),
				[types[2]]: String(opt[types[2]]).split(',').map(v => v==='' ? 'empty' : v),
			}
		})
	}

	return [result,[]]
}

export {
	columnsComputedTracking,
	getDefaultDates,
	goBackward,
	goForward,
	parseDate,
	defaultFilters,
	numberWithSpaces,
	config,
	getMonday,
	sumTrackings,
}
