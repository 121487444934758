export const PAGES = [
    {href: "/", name: "inicio", icon: "ti-home", model: "homePage"},
	{href: "/sales", name: "Ventas", icon: "ti-ticket", model: "booking"},
	{href: "/bookings-lines", name: "Líneas de producto", icon: "ti-notes", model: "bookingLine"},
	{href: "/events", name: "Eventos", icon: "ti-calendar", model: "event"},
	{href: "/roles", name: "Roles", icon: "ti-lock", model: "backofficeRole"},
	{href: "/users", name: "Usuarios", icon: "ti-users", model: "user"},
	{href: "/trackings", name: "Tracking", icon: "ti-chart-infographic", model: "tracking"},
	{href: "/payments", name: "Pagos", icon: "ti-coin-euro", model: "payment"},
	{href: "/price-rules", name: "Pricing rules", icon: "ti-coin", model: "priceRule"},
	{href: "/cashiers", name: "Caja", icon: "ti-building-bank", model: "cashier"},
	{href: "/products", name: "Productos", icon: "ti-packages", model: "product"},
	{href: "/shifts", name: "Pases", icon: "ti-clock-play", model: "shift"},
	{href: "/tables", name: "Mesas", icon: "ti-armchair", model: "table"},
	{href: "/origins", name: "Orígenes", icon: "ti-radar", model: "origin"},
	{href: "/venues", name: "Tablaos", icon: "ti-pin", model: "venue"},
	{href: "/devices", name: "Dispositivos", icon: "ti-device-ipad", model: "device"},
	{href: "/logs", name: "Logs", icon: "ti-database", model: "log"}
]
