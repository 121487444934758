import { useNavigate, useParams, useLocation } from "react-router-dom"
import useApp from "../utils/useApp"
import NoPage from "./NoPage"
import { PAGES } from "../utils/utils"
import { checkPermission } from "../utils/backoffice-roles-data"

const PageWrapper = ({roles = [], noRoles = [], condition = () => true, Page = () => null}) => {

    const {state: { user }} = useApp()
    const params = useParams()
    const location = useLocation()

    const page = PAGES.find(page => page.href === location.pathname)
    const userCanViewPage = checkPermission(`backoffice.${page?.model ?? params.model}.view`)

    return (userCanViewPage) ? Page : <NoPage />
}

export default PageWrapper