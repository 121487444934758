import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const imgPath = 'https://rumba.cardamomo.com/img/products/'

const columnsProducts = [
	{
		name: 'id',
		selector: (row) => row.id,
		sortable: true,
		width: '50px',
	},
	{
		name: 'Name',
		selector: (row) => row.name,
		sortable: true,
		width: '200px',
	},
	{
		name: 'Price',
		selector: (row) => row.price,
		sortable: true,
		width: '70px',
		cell: (props) => (props.price ? props.price.toFixed(2) + ' €' : ''),
	},
	{
		name: 'Status',
		selector: (row) => row.status,
		sortable: true,
		width: '70px',
	},
	{
		name: 'Zone',
		selector: (row) => row.zone,
		sortable: true,
		width: '50px',
	},
	{
		name: 'Category',
		selector: (row) => row.category,
		sortable: true,
		width: '80px',
		center: true,
	},
	{
		name: 'Type',
		selector: (row) => row.type,
		sortable: true,
		width: '70px',
	},
	{
		name: 'Group',
		selector: (row) => row.group,
		sortable: true,
		width: '70px',
	},
	{
		name: 'SubGroup',
		selector: (row) => row.subgroup,
		sortable: true,
		width: '100px',
	},
	// {
	// 	name: 'Icon',
	// 	selector: (row) => row.icon,
	// 	width: '100px',
	// 	center: true,
	// 	cell: (props) => (
	// 		<img src={props.icon ? imgPath + props.icon : '/images/placeholder/no-image.png'} width={60} alt="Icon" className="icon-image" />
	// 	),
	// },
	// {
	// 	name: 'Description',
	// 	selector: (row) => row.description,
	// 	sortable: true,
	// 	width: '200px',
	// },
	// {
	// 	name: 'Availability',
	// 	selector: (row) => row.availability,
	// 	cell: (props) => props.availability ? 'Si' : 'No',
	// 	sortable: true,
	// },
	{
		name: 'Created At',
		selector: (row) => row.createdAt,
		sortable: true,
		cell: (props) => moment(props.createdAt).fromNow(),
	},
	{
		name: 'Updated At',
		selector: (row) => row.updatedAt,
		sortable: true,
		cell: (props) => {
			return moment(props.updatedAt).fromNow()
		},
	},
]



export const productsFiltersRow = [
    {
		id: 'slct-zone',
		key: 'zone',
		type: 'select',
		role: 'filter',
		label: 'Zona',
		preferences: ['selectAllOption', 'showCheckedValuesTag', 'multiSelect', 'toggleSelects'],
		optionsAndTags: () => [
			[
				{ value: 'zv', name: 'Zona VIP' },
				{ value: 'za', name: 'Zona A' },
				{ value: 'zb', name: 'Zona B' },
				{ value: 'zc', name: 'Zona C' },
			],
			[],
		],
	},
    {
		id: 'slct-status',
		key: 'status',
		type: 'select',
		role: 'filter',
		label: 'Estado',
		preferences: ['selectAllOption', 'showCheckedValuesTag', 'multiSelect', 'toggleSelects'],
		optionsAndTags: async () => [
			[
				{ value: 'active', name: 'Activo' },
				{ value: 'inactive', name: 'Inactivo' },
			],
			[]
		]
	},
    {
		id: 'slct-type',
		key: 'type',
		type: 'select',
		role: 'filter',
		label: 'Tipo',
		preferences: ['selectAllOption', 'showCheckedValuesTag', 'multiSelect', 'toggleSelects'],
		optionsAndTags: async () => [
			[
				{value: 'null', name: 'Sin tipo'},
				{value: "pos", name: 'Venta en sala'},
				{value: "ticket", name: 'Venta de entradas'},
				{value: "fnb", name: 'Menús en punto de venta'},
				{value: "other", name: 'Otros productos especiales'},
			],
			[]
		]
	},
]

export const productsFiltersRow2 = [
	{
		id: 'slct-category',
		key: 'category',
		type: 'select',
		role: 'filter',
		placeholder: 'Categoria',
		preferences: ['selectAllOption', 'showCheckedValuesTag', 'multiSelect', 'toggleSelects'],
		optionsAndTags: async () => [
			[
				{value: 'null', name: 'No aplica'},
				{value: "adult", name: 'Adulto'},
				{value: "children", name: 'Infantil'},
				{value: "dinner", name: 'Cenas y menús'},
				{value: "insurance", name: 'Seguros'},
				{value: "other", name: 'Otros'},
			],
			[]
		]
	},
    {
		id: 'slct-group',
		key: 'group',
		type: 'select',
		role: 'filter',
		placeholder: 'Grupo',
		preferences: ['selectAllOption', 'showCheckedValuesTag', 'multiSelect', 'toggleSelects'],
		optionsAndTags: async () => [
			[
				{ value: 'null', name: 'Sin grupo'},
				{ value: 'Licores', name: 'Licores'},
				{ value: 'Carta', name: 'Carta'},
				{ value: 'Bebidas', name: 'Bebidas'},
				{ value: 'Merchandising', name: 'Merchandising'},
				{ value: 'Transfer', name: 'Transfer'},
				{ value: 'Clases', name: 'Clases'},
				{ value: 'ticket', name: 'ticket'},
			],
			[]
		]
	},
    {
		id: 'slct-subgroup',
		key: 'subgroup',
		type: 'select',
		role: 'filter',
		placeholder: 'Subgrupo',
		preferences: ['selectAllOption', 'showCheckedValuesTag', 'multiSelect', 'toggleSelects'],
		optionsAndTags: async () => [
			[
				{ value: 'null', name: 'Sin nombre'},
				{ value: 'Blancos', name: 'Blancos'},
				{ value: 'Cerveza', name: 'Cerveza'},
				{ value: 'Sidra', name: 'Sidra'},
				{ value: 'Rosados', name: 'Rosados'},
				{ value: 'Vodka', name: 'Vodka'},
				{ value: 'Tintos', name: 'Tintos'},
				{ value: 'Cava/Champagne', name: 'Cava/Champagne'},
				{ value: 'Ginebra', name: 'Ginebra'},
				{ value: 'Ron', name: 'Ron'},
				{ value: 'Cognac', name: 'Cognac'},
				{ value: 'Whisky', name: 'Whisky'},
				{ value: 'Tapas', name: 'Tapas'},
				{ value: 'Platos menu', name: 'Platos menu'},
				{ value: 'Menús', name: 'Menús'},
				{ value: 'Bebidas de Bienvenida', name: 'Bebidas de Bienvenida'},
				{ value: 'Refrescos', name: 'Refrescos'},
				{ value: 'Cafe', name: 'Cafe'},
				{ value: 'Clases Grupales', name: 'Clases Grupales'},
				{ value: 'Clases Particulares', name: 'Clases Particulares'},
				{ value: 'Servilletero flamenco', name: 'Servilletero flamenco'},
				{ value: 'Bolas de Navidad', name: 'Bolas de Navidad'},
				{ value: 'Peinetas hechas a mano', name: 'Peinetas hechas a mano'},
				{ value: 'Camiseta  Blanca Peineta Lateral', name: 'Camiseta  Blanca Peineta Lateral'},
				{ value: 'Camiseta Blanca Bailaora Círculo', name: 'Camiseta Blanca Bailaora Círculo'},
				{ value: 'Camiseta Blanca Guitatta Floral', name: 'Camiseta Blanca Guitatta Floral'},
				{ value: 'Camiseta Negra Bailaora', name: 'Camiseta Negra Bailaora'},
				{ value: 'Camiseta Negra Bailaora Con Abanico', name: 'Camiseta Negra Bailaora Con Abanico'},
				{ value: 'Bolsa Tela Orgánica', name: 'Bolsa Tela Orgánica'},
				{ value: 'Camiseta Blanca Zapato Niño/a', name: 'Camiseta Blanca Zapato Niño/a'},
				{ value: 'Camiseta Roja Peineta Frontal', name: 'Camiseta Roja Peineta Frontal'},
				{ value: 'Transfer Hotel', name: 'Transfer Hotel'},
			],
			[]
		]
	},
]
export const productsFiltersRow3 = [
	[{ key: 'autoFilters' }],
	[{ key: 'clearFilters' }, { key: 'applyFilters' }],
]


export { columnsProducts }
