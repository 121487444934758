import DataTableComponent from '../components/DataTableComponent'
import { columnsProducts, productsFiltersRow, productsFiltersRow2, productsFiltersRow3 } from '../utils/products-data'
import React, { useState, useEffect } from 'react'
import { getDataApi, getRumbaVars } from '../services/apiService'
import useApp from '../utils/useApp'
import Filters from '../components/filters/filters'
import ModifiersComponent from '../parts/ModifiersComponent'

const defaultFilters = {
	zone: [],
	group: [],
	subgroup: [],
	type: [],
	category: [],
	status: [],
}

let firstRender = true

const ProductPage = () => {

	const [dataProduct, setDataProduct] = useState([])
	const [loading, setLoading] = useState(false)
	const [totalRows, setTotalRows] = useState(0)
	const [perPage, setPerPage] = useState(100)
	const [prodFilter, setProdFilter] = useState({ fld: '', val: '' })
	const [filters, setFilters] = useState(defaultFilters)
	const [testUserPreferences, setTestUserPreferences] = useState({ autoFilters: 0})

	const {
		state: { user },
	} = useApp()

	const getProductData = async (
		page,
		limit = perPage,
		sortDirection = 'desc',
		sortField = 'id',
		searchTerm = null
	) => {
		setLoading(true)
		const offset = typeof page === 'number' ? (page - 1) * 50 : 0
		const result = await getDataApi(
			user.lastVenue,
			'product',
			0,
			500,
			null,
			null,
			'id',
			'desc',
			searchTerm,
			filters
		)
		if (!result.data) return
		const ProductDataResponse = Object.values(result.data).map((product) => {
			return {
				id: product.id,
				name: product.name,
				price: product.price,
				category: product.category,
				type: product.type,
				icon: product.icon,
				description: product.description,
				availability: product.availability,
				zone: product.zone,
				status: product.status,
				group: product.group,
				subgroup: product.subgroup,
				createdAt: product.createdAt,
				updatedAt: product.updatedAt,
			}
		})
		setDataProduct(ProductDataResponse)
		setTotalRows(result.count)
		setLoading(false)
	}

	useEffect(() => {
		if (firstRender) {
			firstRender =  false
			return
		}
		getProductData(undefined, 100, 'desc', 'id', '')
	}, [filters])

	return (
		<>
			<div className="card">
				<div className="card-body">
					<Filters
						config={{
							page: 'productsPage',
							localStorageItem: 'products-filters',
							filtersInterface: defaultFilters,
							preferencesInterface: { autoFilters: 0},
							rows: [
								[
									{ role: 'layout', inner: 'Productos' },
									[{ key: 'update', action: getProductData },],
								],
								productsFiltersRow,
								productsFiltersRow2,
								productsFiltersRow3
							],
						}}
						onFilterChange={setFilters}
						onPreferenceChange={setTestUserPreferences}
					/>
				<hr />
					<DataTableComponent
						title="Listado de productos"
						description="Listado de productos del tablao"
						columns={columnsProducts}
						data={dataProduct}
						loading={loading}
						noPagination
						model="product"
						addNew
					/>
				<hr />
				<ModifiersComponent />
				</div>
			</div>
		</>
	)
}
export default ProductPage
