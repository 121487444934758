import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const columnsShifts = [
    {
        name: 'Id',
        selector: row => row.id,
        sortable: true,
        width: '50px',
    },
    {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
    },
    {
        name: 'Time',
        selector: row => row.time,
        sortable: true,
        width: '70px',
    },
    {
        name: 'Type',
        selector: row => row.type,
        sortable: true,
        width: '70px',
    },
    {
        name: 'Hall Id',
        selector: row => row.hallId,
        sortable: true,
        width: '70px',
        center: true
    },
    {
        name: 'Created At',
        selector: row => row.createdAt,
        sortable: true,
        cell: props => moment(props.createdAt).fromNow(),
        width: '200px',
    },
    {
        name: 'Updated At',
        selector: row => row.updatedAt,
        sortable: true,
        cell: props => {return moment(props.updatedAt).fromNow()},
        width: '200px',
    }
]

const dataShifts = [
    {
        id: 1,
        name: '18:00',
        time: '18:00',
        type: 'standard',
        hallId: 1,
        venueId: 1,
        createdAt: '2023-04-08 11:28:24',
        updatedAt: '2023-04-08 11:28:241',
    },
    {
        id: 2,
        name: '19:30',
        time: '19:30',
        type: 'standard',
        hallId: 2,
        venueId: 2,
        createdAt: '2023-04-11 11:28:24',
        updatedAt: '2023-04-11 11:28:24',
    }
]

export { columnsShifts, dataShifts }