import useApp from '../utils/useApp'
import { useEffect, useState } from 'react'
import { getDashboardApi } from '../services/apiService'

const DashboardComponent = () => {
	const {
		state: { user },
	} = useApp()
	const [dashboardData, setDashboardData] = useState(null)

	useEffect(() => {
		getDashboardData()
		// eslint-disable-next-line
	}, [])

	const getDashboardData = async () => {
		const from = new Date().toISOString().slice(0, 10)
		const to = new Date().toISOString().slice(0, 10)
		const response = await getDashboardApi(user.lastVenue, from, to)
		if (response.result === 'nok') return
		response.totalSales = response.salesData.reduce((acc, sale) => acc + sale.price, 0)
		response.pax = response.salesData.reduce((acc, sale) => acc + sale.pax, 0)
		response.dinners = response.salesData.reduce((acc, sale) => acc + sale.meals, 0)
		response.bookings = response.salesData.length
		setDashboardData(response)
	}

	const parseDate = (date) => {
		const dateJs = new Date(date)
		if (dateJs instanceof Date && !isNaN(dateJs)) {
			return dateJs.toLocaleDateString('es-ES', {
				weekday: 'short',
				year: 'numeric',
				month: 'short',
				day: 'numeric',
			})
		} else return ''
	}
	const parsePrice = (price) => {
		if (!isNaN(parseFloat(price))) {
			return parseFloat(price).toLocaleString('es-ES', { style: 'currency', currency: 'EUR' })
		} else return ''
	}

	return (
		<div className="card">
			{dashboardData && (
				<>
					<div className="card-body">
						<div className="row pb-4">
							<div className="col-lg-4 d-flex align-items-stretch">
								<div className="d-flex flex-column align-items-start w-100">
									<div className="text-start">
										<h5 className="card-title fw-semibold">Informe de ventas</h5>
										<span>
											{parseDate(dashboardData.fromDate)} - {parseDate(dashboardData.toDate)}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="border-top">
						<div className="row gx-0">
							<div className="col-md-3 border-end">
								<div className="p-4 py-3 py-md-4">
									<p className="fs-4 text-danger mb-0">
										<span className="text-danger">
											<span className="round-8 bg-danger rounded-circle d-inline-block me-1"></span>
										</span>
										Ventas
									</p>
									<h3 className=" mt-2 mb-0">{parsePrice(dashboardData.totalSales)}</h3>
								</div>
							</div>
							<div className="col-md-3 border-end">
								<div className="p-4 py-3 py-md-4">
									<p className="fs-4 text-danger mb-0">
										<span className="text-danger">
											<span className="round-8 bg-danger rounded-circle d-inline-block me-1"></span>
										</span>
										Entradas
									</p>
									<h3 className=" mt-2 mb-0">{dashboardData.pax}</h3>
								</div>
							</div>
							<div className="col-md-3 border-end">
								<div className="p-4 py-3 py-md-4">
									<p className="fs-4 text-primary mb-0">
										<span className="text-primary">
											<span className="round-8 bg-primary rounded-circle d-inline-block me-1"></span>
										</span>
										Cenas
									</p>
									<h3 className=" mt-2 mb-0">{dashboardData.dinners}</h3>
								</div>
							</div>
							<div className="col-md-3">
								<div className="p-4 py-3 py-md-4">
									<p className="fs-4 text-info mb-0">
										<span className="text-info">
											<span className="round-8 bg-info rounded-circle d-inline-block me-1"></span>
										</span>
										Reservas
									</p>
									<h3 className=" mt-2 mb-0">{dashboardData.bookings}</h3>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	)
}
export default DashboardComponent
