import { useState, useEffect, useRef } from "react"
import InputSelect from "../InputSelect/InputSelect"
import useApp from "../../utils/useApp"

const InputSelectWrapper = ({config, filters, onInput, clearFilters, disable}) => {

    const immutableOptions = useRef([])
    const [options, setOptions] = useState([])
    const [tags, setTags] = useState([])
    const {state:{user}} = useApp()

    const setUp = async () => {
        let result = await config.optionsAndTags(user.sa)
        return result
    }

    useEffect(()=>{
        setUp().then(([options, tags]) => {
            immutableOptions.current = options
            if (options.length && options[0].dependencies) 
                updateOptionsBasedOnDependencies()
            else setOptions(options)
            setTags(tags)
        })
    },[])

    const updateOptionsBasedOnDependencies = () => {

        const newOptions = immutableOptions.current.filter((opt) => {

            let dependencies = Object.keys(opt.dependencies)
            let available = []

            for (let i = 0; i < dependencies.length; i++) {

                let depIsArray = Array.isArray(opt.dependencies[dependencies[i]])
                let filIsArray = Array.isArray(filters[dependencies[i]])

                if (
                    (
                        depIsArray && filIsArray && //both array
                        opt.dependencies[dependencies[i]].some(v => !!v) &&
                        filters[dependencies[i]].some(v => !!v) && //filter is setted & not ['', false...]
                        filters[dependencies[i]].some(v => opt.dependencies[dependencies[i]].some(w => v == w))//some value in filters is equal to some value in dependencies
                    ) 
                    || 
                    (
                        depIsArray && filIsArray &&//both array
                        !filters[dependencies[i]].some(v => !!v) 
                    ) 
                    || 
                    (
                        depIsArray && !filIsArray &&//dep is array filter is string / number
                        opt.dependencies[dependencies[i]].some(v => !!v) && // dependencies has some non falsy values
                        !!filters[dependencies[i]] &&//filter is not a falsy value, ex: venueId: 0
                        opt.dependencies[dependencies[i]].some(v => v == filters[dependencies[i]])//some value in dependencies is equal to filter value
                    ) 
                    || 
                    (
                        !depIsArray && filIsArray &&//dep is string / value filter is array
                        !!opt.dependencies[dependencies[i]] &&//dependencie is a non falsy value
                        filters[dependencies[i]].some(v => !!v) &&//filter has some non falsy value
                        filters[dependencies[i]].some(v => v == opt.dependencies[dependencies[i]])//some value in filters is equal to dependencie
                    ) 
                    || 
                    (
                        !depIsArray && !filIsArray &&//both string / number
                        filters[dependencies[i]] == opt.dependencies[dependencies[i]]
                    ) 
                    || 
                    (
                        !depIsArray && !filIsArray && //both string / number & falsy values
                        !!!filters[dependencies[i]]
                    )
                ) {
                    available.push(1)
                }
            }
            
            return (available.length >= dependencies.length)
        })

        setOptions(newOptions)
    }
    
    useEffect(() => {
        if (immutableOptions.current.length && Object.keys(immutableOptions.current[0]).includes('dependencies')) {
            updateOptionsBasedOnDependencies()
        }
    }, [filters])
    

    return (
        <InputSelect
            selectId={config.id}
            name={config.key}
            placeholder={config.placeholder}
            onInput={onInput}
            options={options}
            setOptions={setOptions}
            tags={tags}
            label={config.label ? config.label : ''}
            setTags={setTags}
            defaultValue={filters[config.key]}
            searchable={config.preferences.includes('searchable')}
            showCurrentValue={config.preferences.includes('showCurrentValue')}
            showCheckedValuesTag={config.preferences.includes('showCheckedValuesTag')}
            multiSelect={config.preferences.includes('multiSelect')}
            selectAllOption={config.preferences.includes('selectAllOption')}
            autoHide={config.preferences.includes('autoHide')}
            toggleSelects={config.preferences.includes('toggleSelects')}
            onlyOne={config.preferences.includes('onlyOne')}
            clearFilters={clearFilters}

            disable={disable}
        />
    )
}

export default InputSelectWrapper