import moment from 'moment'
import 'moment/locale/es'
import { getOptions } from '../services/apiService'
import { checkPermission } from './backoffice-roles-data'
moment.locale('es')

export const defaultCashiersFilters = {
    venueId: 1,
    userId: undefined,
    createdAt: [new Date().toISOString().substring(0, 10), new Date().toISOString().substring(0, 10)]
}


export const cashiersFiltersRow = [
    {
		id: 'slct-tablao',
		key: 'venueId',
		type: 'select',
		role: 'filter',
		label: 'Tablao',
		preferences: ['onlyOne', 'autoHide', 'showCurrentValue', 'toggleSelects'],
		optionsAndTags: () => [
			[
				{ value: 0, name: 'Todos' },
				{ value: 1, name: 'Cardamomo' },
				{ value: 2, name: '1911' },
			],
			[],
		],
	},
    checkPermission('backoffice.cashier.filter.allowFilterByAnyUserId') ?
    {
		id: 'slct-seller',
		key: 'userId',
		type: 'select',
		role: 'filter',
		label: 'Usuario',
		preferences: ['onlyOne', 'autoHide', 'showCurrentValue', 'toggleSelects', 'searchable', 'selectAllOption'],
		optionsAndTags: async () => {
			const res = await getOptions('userId')
			const options = res.userId.map(({id, name, origins}) => {
				return {
					name: name,
					value: id,
				}
			})
			return [options, []]
		},
	} : undefined,
    { key: 'createdAt', type: 'date', label: 'Desde - hasta', role: 'filter' },
]

export const cashiersFiltersRow2 = [
	[{ key: 'autoFilters' }],
	[{ key: 'clearFilters' }, { key: 'applyFilters' }],
]

const columnsCashiers = [
    {
        name: 'id',
        selector: row => row.id,
        sortable: true,
        width: '100px'
    },
    {
        name: 'Method',
        selector: row => row.method,
        sortable: true
    },
    {
        name: 'Type',
        selector: row => row.type,
        cell: props => {
            if (props.type === 'total') {
                return <span className="badge bg-light-success text-success">{props.type}</span>
            } else {
                return <span className="badge bg-light-warning text-warning">{props.type}</span>
            }
        },
        sortable: true,
        width: '100px'
    },
    {
        name: 'Amount',
        selector: row => row.amount,
        sortable: true,
        width: '100px',
        cell: props => props.amount ? props.amount.toFixed(2) + ' €' : ''
    },
    {
        name: 'Cashier',
        selector: row => row.cashier,
        sortable: true
    },
    {
        name: 'User Name',
        selector: row => row.userId,
        sortable: true,
        width: '100px'
    },
    {
        name: 'Booking Id',
        selector: row => row.bookingId,
        sortable: true,
        cell: (props) => (
            <a href={`booking/edit/${props.bookingId}`} className='rmb-text-primary booking-id-link'>
                {props.bookingId}
                <i className="ti ti-link fs-7"></i>
            </a>
        )
        
    },
    {
        name: 'Created At',
        selector: row => row.createdAt,
        sortable: true,
        cell: props => moment(props.createdAt).format('DD/MM/YYYY HH:mm:ss')
    }
]

export { columnsCashiers }


