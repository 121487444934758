import { useEffect, useRef, useState } from "react";

export const Chart = ({ id, data = [], labels = [] }) => {
  const chartRef = useRef(null); // Referencia para el gráfico
  const [config, setConfig] = useState({
    series: [
      {
        name: "Usuarios",
        data: data,
      },
      {
        name: "Usuarios perdidos",
        data: data.map((value, index, array) => {
          if (index === 0) return index;
          return array[index - 1] - value;
        }),
      },
    ],
    chart: {
      toolbar: {
        show: true,
      },
      type: "bar",
      fontFamily: "Plus Jakarta Sans', sans-serif",
      foreColor: "#adb0bb",
      height: 320,
      stacked: true,
    },
    colors: ["var(--rmb-secondary)", "var(--rmb-secondary-light)"],
    plotOptions: {
      bar: {
        columnWidth: "50%",
        borderRadius: 7,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    grid: {
      borderColor: "rgba(0,0,0,0.2)",
      strokeDashArray: 3,
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    yaxis: {
      tickAmount: 6,
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      categories: labels,
    },
    tooltip: {
      theme: "dark",
    },
  });

  useEffect(() => {
    const container = document.getElementById(id);

    // Crea el gráfico solo si no existe
    if (!chartRef.current) {
      chartRef.current = new window.ApexCharts(container, config);
      chartRef.current.render();
    }

    return () => {
      // Limpia el gráfico al desmontar el componente
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };
  }, [id, config]);

  useEffect(() => {
    if (chartRef.current) {
      // Actualiza las series y las categorías del gráfico dinámicamente
      chartRef.current.updateOptions({
        series: [
          {
            name: "Usuarios",
            data: data,
          },
          {
            name: "Usuarios perdidos",
            data: data.map((value, index, array) => {
              if (index === 0) return index;
              return array[index - 1] - value;
            }),
          },
        ],
        xaxis: {
          categories: labels,
        },
      });
    }
  }, [data, labels]);

  return <div id={id}></div>;
};
