import DataTableComponent from "../components/DataTableComponent"
import { useState, useEffect } from "react"
import useApp from "../utils/useApp"
import { useNavigate } from "react-router-dom"
import { getDataApi } from "../services/apiService"
import { columnsRole } from "../utils/backoffice-roles-data"

export const BackofficeRolesPage = () => {

    const {state: { user }} = useApp()
    const [data, setData] = useState([])
    const navigate = useNavigate()

    const getData = async () => {

        const response = await getDataApi(0, 'backofficeRole', 0, 100)
        setData(response.data)
    }

    const handleAddNew = () => navigate('/backofficeRole/edit/0')

    useEffect(() => {

        getData()
    }, [])

    return (

        <div className='card'>
            <div className='card-body'>
            <DataTableComponent
                    title="Roles de usuario"
                    columns={columnsRole}
                    data={data}
                    model="backofficeRole"
                    sercheable={false}
                    RightSideComponent={() => {
                        return (
                            <button type="button" className="btn rmb-btn-primary maxh-37" onClick={handleAddNew}>
								Nuevo Rol
							</button>
                        )
                    }}
                />
            </div>
        </div>

    )

}