import WelcomeComponent from "../components/WelcomeComponent";
import DashboardComponent from "../components/DashboardComponent";
import useApp from "../utils/useApp";
import { checkPermission } from "../utils/backoffice-roles-data";

const HomePage = () => {

  const {state: { user }} = useApp()

  return (
    <div className="row">
      <div className="col-12">
        <WelcomeComponent />
        {checkPermission('backoffice.homePage.view.dashboard') && (<DashboardComponent />)}
      </div>
    </div>
  );
};
export default HomePage;
