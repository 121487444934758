import { getValueByPath, permissions } from "../utils/backoffice-roles-data"
import { PAGES } from "../utils/utils"

const keyMap = {
    view: 'Permitir ver',
    write: 'Permitir crear / actualizar / borrar',
    webOriginsExcluded: 'Excluir orígenes web',
    allowFilterByAnyUserId: 'Permitir filtrar por cualquier o ningún usuario, viendo las ventas totales.<br><small> *** Si esta casilla NO está marcada, el usuario solo podrá ver sus ventas.</small>',
    originParent1: 'Con origen padre Hoteles',
    originParent2: 'Con origen padre Puntos de venta',
    originParent3: 'Con origen padre Web',
    originParent13: 'Con origen padre Intermediarios',
    originParent59: 'Con origen padre MICE',
    originParent61: 'Con origen padre Invitaciones',
    originParent69: 'Con origen padre Guías',
    originParent297: 'Con origen padre Teléfono',
    originParent303: 'Con origen padre Revo - Antiguos',
    originParent5001: 'Con origen padre Otros',
}

export const Permissions = ({data, permissions, updateData, path = '', depth = 0}) => {

    return (
        <>
        {
            Object.entries(permissions).map(([key, value], index) => {

                const thisPath = path === '' ? key : path+'.'+key
                const valueInMock = getValueByPath(permissions, key)
                const valueInData = getValueByPath(data, thisPath)

                const isParent = typeof valueInMock === 'object' && !Array.isArray(valueInMock)

                const page = PAGES.find(page => page.model === key)

                const selectValue = 
                    valueInData === true ? 2 :
                    typeof valueInData === 'object' ? 3 :
                    1
                
                return (
                    <div key={index} className={`permission-container depth-${depth}`} style={{'--depth': depth}}>
                        <div className={`permission ${isParent ? 'parent' : ''}`}>
                            {isParent ?
                                <>
                                <p 
                                    className="permission-name"
                                    dangerouslySetInnerHTML={{__html: page?.name ?? keyMap[key] ?? key}}
                                ></p>
                               <div className="radio-set" >
                                    <button 
                                        onClick={e => updateData(thisPath, false)}
                                        className={`${selectValue === 1 ? 'selected' : ''} permission-button`}
                                    >
                                        Nada
                                    </button>
                                    <button 
                                        onClick={e => updateData(thisPath, valueInMock)}
                                        className={`${selectValue === 3 ? 'selected' : ''} permission-button`}
                                    >
                                        Especificar
                                    </button>
                                    <button 
                                        onClick={e => updateData(thisPath, true)}
                                        className={`${selectValue === 2 ? 'selected' : ''} permission-button`}
                                    >
                                        Todo
                                    </button>
                                </div>

                                </>

                                :
                                <>
                                <input type="checkbox" checked={valueInData} onChange={e => updateData(thisPath, e.target.checked)}/>
                                <p className="permission-name" dangerouslySetInnerHTML={{__html: keyMap[key] ?? key}}></p>
                                </>
                            }
                        </div>
                        <div className="nested-permissions">
                        {typeof valueInData === 'object' && (
                            <Permissions data={data} permissions={permissions[key]} updateData={updateData} path={thisPath} depth={depth + 1}/>
                        )}
                        </div>
                    </div>
                )
            })
        }
        </>
    )
}