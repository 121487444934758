import { useState, useEffect } from 'react'
import { getDateString } from '../../utils/bookings-data'

const InputDateWrapper = ({ config, setFilters, filters, onInput, clearFilters, disable, minDate }) => {
	const [todayToggle, setTodayToggle] = useState(true)
	const [monthToggle, setMonthToggle] = useState(true)
	
	const addTime = (i) => config.type==='date' ? '' : i ? 'T23:59' : 'T00:00'

	const setToday = () => {
		const nowIso = new Date().toISOString().substring(0, 10)
		setFilters((prev) => ({
			...prev,
			[config.key]: todayToggle ? [nowIso + addTime(0), nowIso + addTime(1)] : ['', ''],
		}))
		setMonthToggle(true)
		setTodayToggle(!todayToggle)
	}

	const setCurrentMonth = () => {
		const now = new Date()
		const firstDay = new Date(now.getFullYear(), now.getMonth(), 2).toISOString().substring(0, 10)
		const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 1).toISOString().substring(0, 10)
		setFilters((prev) => ({
			...prev,
			[config.key]: monthToggle ? [firstDay + addTime(0), lastDay + addTime(1)] : ['', ''],
		}))
		setTodayToggle(true)
		setMonthToggle(!monthToggle)
	}

	const clearDate = () => {
		setTodayToggle(true)
		setMonthToggle(true)
		if (minDate) setFilters((prev) => ({ ...prev, [config.key]: [
				getDateString(Date.now()) + 'T00:00', 
				getDateString(Date.now()) + 'T23:59'
			]}))
		else setFilters((prev) => ({ ...prev, [config.key]: ['', '']}))
	}

	const addRemoveDay = (add) => {
		const nowIso = new Date().toISOString()
		const newDate = new Date(filters[config.key][0] || nowIso)
		const newDate2 = new Date(filters[config.key][1] || nowIso)
		const diff = add ? 1 : -1
		const gmtDiff1 = newDate.getTimezoneOffset() * 60000
		const gmtDiff2 = newDate2.getTimezoneOffset() * 60000
		newDate.setDate(newDate.getDate() + diff)
		newDate2.setDate(newDate2.getDate() + diff)
		setFilters((prev) => {
			let filters = { ...prev }
			filters[config.key] = [
				new Date(newDate.getTime() - gmtDiff1).toISOString().substring(0, 10) + addTime(0),
				new Date(newDate2.getTime() - gmtDiff2).toISOString().substring(0, 10) + addTime(1),
			]
			return filters
		})
	}

	// useEffect(clearDate, [clearFilters])

	return (
		<>
			<div className={`date-filter date-filter-from ${disable ? 'disable' : ''}`}>
				<div>
					<p>{config.label}</p>
					<div>
						<button className="ti ti-plus" onClick={() => addRemoveDay(true)}></button>
						<button className="ti ti-minus" onClick={() => addRemoveDay(false)}></button>
					</div>
				</div>
				<input
					type={config.type}
					onDoubleClick={
						config.type === 'datetime-local'
							? (e) => {
									if (e.currentTarget.value !== '') {
										e.currentTarget.value = e.currentTarget.value.split('T')[0] + 'T00:00'
										onInput(e)
									}
							  }
							: () => {}
					}
					name={`${config.key}-1`}
					min={minDate}
					className={`${filters[config.key][0].length === 0 ? 'unsetted' : ''}`}
					onInput={onInput}
					value={filters[config.key][0]}
				/>
			</div>
			<div className={`date-filter date-filter-to ${disable ? 'disable' : ''}`}>
				<div className="month-buttons">
					<button className={`${!todayToggle ? 'active' : ''}`} onClick={() => setToday()}>
						Hoy
					</button>
					<button className={`${!monthToggle ? 'active' : ''}`} onClick={() => setCurrentMonth()}>
						Mes
					</button>
					<button className="ti-arrow-back-up ti" onClick={() => clearDate()}></button>
				</div>
				<input
					type={config.type}
					onDoubleClick={
						config.type === 'datetime-local'
							? (e) => {
									if (e.currentTarget.value !== '') {
										e.currentTarget.value = e.currentTarget.value.split('T')[0] + 'T23:59'
										onInput(e)
									}
							  }
							: () => {}
					}
					name={`${config.key}-2`}
					min={minDate}
					className={`${filters[config.key][1].length === 0 ? 'unsetted' : ''}`}
					onInput={onInput}
					value={filters[config.key][1]}
				/>
			</div>
		</>
	)
}

export default InputDateWrapper
