import DataTableComponent from "../components/DataTableComponent"
import InputSelect from "../components/InputSelect/InputSelect.js" 
import { columnsOrigins } from "../utils/origins-data"
import React, { useState, useEffect } from 'react'
import { getDataApi } from "../services/apiService"
import useApp from '../utils/useApp'
import { useNavigate } from 'react-router-dom'
import References from "../parts/OriginChildren"


const OriginPage = () => {

	const [dataOrigin, setDataOrigin] = useState([])
	const [loading, setLoading] = useState(false)
	const [totalRows, setTotalRows] = useState(0)
	const [sortedOrigins, setSortedOrigins] = useState({})

	const navigate = useNavigate()

	const getOriginData = async () => {
		setLoading(true)
		const result = await getDataApi(0, 'origin', 0, 3000, 'parent', null, 'id', 'asc')
		
		const OriginDataResponse = Object.values(result.data)
		let newObj = {}
		let parentNames = []
		OriginDataResponse
			.sort((a,b)=> a.parent - b.parent)
			.forEach((origin) => {
				const dataFormat = {
					id: origin.id,
					name: origin.name,
					parent: origin.parent,
					order: origin.order,
					venueId: origin.venueId,
					createdAt: origin.createdAt,
					updatedAt: origin.updatedAt
				}
				if (origin.parent === 0) {
					newObj[origin.name] = newObj[origin.name] || [dataFormat]
					parentNames.push({name: origin.name, id: origin.id})
				} else {
					newObj[parentNames.find((x)=>x.id===origin.parent).name].push(dataFormat)
				}
			})
		setSortedOrigins(newObj)
		setDataOrigin(Object.values(newObj).map((parent) => {return parent[0]}))

		// .filter( (x) => x.parent == 0)
		// .map((origin) => {
		// 	return {
		// 	id: origin.id,
		// 	name: origin.name,
		// 	parent: origin.parent,
		// 	order: origin.order,
		// 	venueId: origin.venueId,
		// 	createdAt: origin.createdAt,
		// 	updatedAt: origin.updatedAt
		// 	}
		// })
		// setDataOrigin(OriginDataResponse)
		// console.log(OriginDataResponse)
		setTotalRows(result.count)
		setLoading(false)
	}

	const handleNew = (e) => {
		const toUrl = `/origin/edit/0`
		navigate(toUrl)
	}

	useEffect(() => {getOriginData()}, [])

	return (
		<div className="card origins-page">
			<div className="card-body">
				<div className="row">
					<div className="col-12">
						<DataTableComponent
							sercheable={false}
							title="Orígenes de pagos"
							description="Listado de origenes del tablao"
							columns={columnsOrigins}
							data={dataOrigin}
							loading={loading}
							getData={() => {}}
							totalRows={totalRows}
							model="origin"
							noPagination={true}
							expandableRows={true}
							expandableRowsComponent={({data}) => <References formData={sortedOrigins[data.name]}/>}
							RightSideComponent={() => 
								<button type="button" className="btn rmb-btn-primary" onClick={handleNew}>
									Nuevo origen
								</button>
							}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
export default OriginPage
