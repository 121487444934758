import { useEffect, useState } from 'react';
import moment from 'moment'
import 'moment/locale/es'
import { checkPermission } from '../utils/backoffice-roles-data';
moment.locale('es')

const BookingLinesForm = ({data, sendToUpdate}) => {
    const [formData, setFormData] = useState({})
    const [fieldsToSend, setFieldsToSend] = useState([])

    useEffect(() => {
        setFormData(data)
    }, [data])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFieldsToSend(new Set([...fieldsToSend, name]))
        setFormData({
            ...formData,
            [name]: value,
        })
    }

    const handleSave = () => {
        if(fieldsToSend.size > 0) {
            const dataToSend = Object.keys(formData)
            .filter((key) => fieldsToSend.has(key))
            .reduce((obj, key) => {
                obj[key] = formData[key];
                return obj;
            }, {})

            // console.log('dataToSend', dataToSend)
            sendToUpdate(dataToSend)
        }
    }

    return (
        <form className="mt-4 px-4">
            <div className="d-sm-flex align-items-center justify-content-between mb-3">
                <div className="form-group">
                    <h6 className="mb-0 fs-4 mb-2">Id</h6>
                    <div className="form-group">
                        <input type="text" className="form-control" id="bookingLinesId" placeholder="Id" defaultValue={formData.id} disabled />
                    </div>
                </div>
            </div>
            <div className="form-group mt-4">
                <h6 className="mb-0 fs-4 mb-2">Descripción</h6>
                <textarea type="text" className="form-control" name="desc" id="bookingLinesDesc" defaultValue={formData.desc} onChange={handleInputChange} ></textarea>
            </div>
            
            <div className="d-sm-flex align-items-center mb-3">
                <div className="form-group mt-4 col-3">
                    <h6 className="mb-0 fs-4 mb-2">Código</h6>
                    <input type="text" className="form-control" name="code" id="bookingLinesCode" defaultValue={formData.code} onChange={handleInputChange} />
                </div>
                <div className="form-group mt-4 col-3 p-2">
                    <h6 className="mb-0 fs-4 mb-2">Tipo</h6>
                    <input type="text" className="form-control" name="type" id="bookingLinesType" defaultValue={formData.type} onChange={handleInputChange} />
                </div>
                <div className="form-group mt-4 col-3 p-2">
                    <h6 className="mb-0 fs-4 mb-2">Zona</h6>
                    <input type="text" className="form-control" rows="6" name="zone" id="bookingLinesZone" defaultValue={formData.zone} onChange={handleInputChange} />
                </div>
                <div className="form-group mt-4 col-3">
                    <h6 className="mb-0 fs-4 mb-2">Precio</h6>
                    <input type="number" className="form-control" rows="6" name="price" id="bookingLinesPrice" defaultValue={formData.price}   pattern="[0-9]+([\.,][0-9]+)?" step="1" onChange={handleInputChange} />
                </div>
            </div>
            <div className="d-sm-flex align-items-center mb-3">
                <div className="form-group mt-4 p-2 col-3">
                    <h6 className="mb-0 fs-4 mb-2">Qty</h6>
                    <input type="number" className="form-control" rows="6" name="qty" id="bookingLinesQty" defaultValue={formData.qty} onChange={handleInputChange} />
                </div>
                <div className="form-group mt-4 p-2 col-3 p-2">
                    <h6 className="mb-0 fs-4 mb-2">Descuento</h6>
                    <input type="number" className="form-control" rows="6" name="disc" id="bookingLinesDisc" defaultValue={formData.disc} onChange={handleInputChange} />
                </div>
                <div className="form-group mt-4 col-3 p-2">
                    <h6 className="mb-0 fs-4 mb-2">Id Booking</h6>
                    <input type="number" className="form-control" rows="6" name="bookingId" id="bookingLinesBookingId" defaultValue={formData.bookingId} onChange={handleInputChange} disabled/>
                </div>
                <div className="form-group mt-4 col-3">
                    <h6 className="mb-0 fs-4 mb-2">Id Producto</h6>
                    <input type="number" className="form-control" rows="6" name="productId" id="bookingLinesProductId" defaultValue={formData.productId} onChange={handleInputChange} disabled />
                </div>
            </div>
            <div className="d-flex align-items-right fs-2">Actualizado: {moment(formData.updatedAt).fromNow()} | Creado: {moment(formData.createdAt).fromNow()}</div>
            { checkPermission(`backoffice.bookingLine.write`) && (
                <button type="button" className="mt-4 btn rmb-btn-primary" onClick={handleSave}>
                    Guardar
                </button>
            )}
        </form>
        
        
    )
}

export default BookingLinesForm;