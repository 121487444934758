import DataTableComponent from '../components/DataTableComponent'
import { cashiersFiltersRow, cashiersFiltersRow2, columnsCashiers, defaultCashiersFilters } from '../utils/cashiers-data'
import React, { useEffect, useState } from 'react'
import { getCashiers, getDataApi } from '../services/apiService'
import useApp from '../utils/useApp'
import TotalsCashiersComponent from '../parts/TotalsCashiersComponent'
import CashiersCashBoxesDatatable from '../parts/CashiersCashBoxesDatatable'
import Filters from '../components/filters/filters'
import { checkPermission } from '../utils/backoffice-roles-data'

const CashiersPage = () => {
	const [cashiersData, setCashiersData] = useState([])
	const [cashiersCashBoxes, setCashiersCashBoxes] = useState([])
	const [filters, setFilters] = useState(defaultCashiersFilters)
	const [testUserPreferences, setTestUserPreferences] = useState({ autoFilters: 0})
	const [loading, setLoading] = useState(false)
	const [totalRows, setTotalRows] = useState(0)
	const [perPage, setPerPage] = useState(1000)

	const {
		state: { user },
	} = useApp()

	const getCashiersData = async () => {
		setLoading(true)
		const result = await getCashiers({...filters, userId: checkPermission('backoffice.cashier.filters.allowFilterByAnyUserId') ? filters.userId : user.id})
		setCashiersData(result.payments)
		setCashiersCashBoxes(result.cashboxes)
		setTotalRows(result.count)
		setLoading(false)
	}

	useEffect(() => {

		getCashiersData()

	}, [filters])

	return (
		<div className="card" id="cashiers-page-card">
			<div className="card-body">
				<Filters
					config={{
						page: 'cashiersPage',
						localStorageItem: 'cashiers-filters',
						filtersInterface: defaultCashiersFilters,
						preferencesInterface: { autoFilters: 0},
						rows: [
							[
								{ role: 'layout', inner: 'CAJAS' },
								[{ key: 'update', action: getCashiersData },],
							],
							cashiersFiltersRow,
							cashiersFiltersRow2
						],
					}}
					onFilterChange={setFilters}
					onPreferenceChange={setTestUserPreferences}
				/>
				<hr />
				<DataTableComponent
					title="Control de caja"
					description="Revisión de las entradas y salidas de caja"
					columns={columnsCashiers}
					data={cashiersData}
					setPerPage={setPerPage}
					loading={loading}
					totalRows={totalRows}
					perPage={perPage}
					model="payment"
				/>
				{checkPermission('backoffice.cashier.view.totals') && (
					<>
					<hr />
					<TotalsCashiersComponent cashiersData={cashiersData} />
					</>
				)}
				{/* <CashiersCashBoxesDatatable cashiersCashBoxes={cashiersCashBoxes} /> */}
			</div>
		</div>
	)
}
export default CashiersPage
