import { useEffect, useState } from 'react'
import { uploadFile } from '../services/apiService'
import { useNavigate } from 'react-router-dom'
import { getDataApi } from '../services/apiService'
import useApp from '../utils/useApp'
import moment from 'moment'
import 'moment/locale/es'
import { checkPermission } from '../utils/backoffice-roles-data'
moment.locale('es')

const imgPath = 'https://rumba.cardamomo.com/img/products/'

const ProductForm = ({ data, sendToUpdate, responseMessage }) => {
	const [formData, setFormData] = useState({})
	const [fieldsToSend, setFieldsToSend] = useState([])
	const [icon, setIcon] = useState(null)
	const [productIcon, setProductIcon] = useState(null)
	const [allModifiers, setAllModifiers] = useState([])
	const [notCurrentModifiers, setNotCurrentModifiers] = useState([])
	const [currentModifiers, setCurrentModifiers] = useState([])
	const [modifiersToAdd, setModifiersToAdd] = useState([])
	const navigate = useNavigate()
	const {
		state: { user },
	} = useApp()

	useEffect(() => {
		setFormData(data)
		setIcon(imgPath + data.icon)
		setCurrentModifiers(data.modifiers || [])
	}, [data])

	useEffect(() => {
		if (productIcon) {
			const file = productIcon.files[0]
			if (file) {
				uploadAndSetIcon(file)
			}
		}
		// eslint-disable-next-line
	}, [productIcon])

	useEffect(() => {
		getModifiersData()
		// eslint-disable-next-line
	}, [])

	const getModifiersData = async () => {
		const result = await getDataApi(user.lastVenue, 'modifier')
		if (result.result === 'ok') {
			setAllModifiers(result.data)
			let notCurrentModif = result.data.filter((modifier) => !currentModifiers.includes(modifier.id))
			//order
			notCurrentModif.sort((a, b) => (a.name > b.name ? 1 : -1))
			setNotCurrentModifiers(notCurrentModif)
		}
	}

	const uploadAndSetIcon = async (file) => {
		try {
			const res = await uploadFile(file, 'products', 'image')
			// console.log('res', res)
			if (res.result === 'ok') {
				setIcon(res.data.fullPath)
				setFormData({
					...formData,
					icon: res.data.name,
				})
			}
		} catch (error) {
			// console.log('error', error)
		}
	}

	const handleInputChange = (e) => {
		const { name, value } = e.target
		setFieldsToSend(new Set([...fieldsToSend, name]))

		setFormData({
			...formData,
			[name]: value,
		})
	}

	const handleSave = async () => {
		const fieldsToSend = new Set(Object.keys(formData))

		if (fieldsToSend.size > 0) {
			const dataToSend = Object.keys(formData)
				.filter((key) => fieldsToSend.has(key))
				.reduce((obj, key) => {
					obj[key] = formData[key]
					return obj
				}, {})
			// console.log('dataToSend', dataToSend)
			await sendToUpdate(dataToSend)
		}
	}

	const addModifiers = (e) => {
		let currentModifiers = formData.modifiers || []
		setCurrentModifiers([...currentModifiers, ...modifiersToAdd])
		setFieldsToSend(new Set([...fieldsToSend, 'modifiers']))
		setFormData({
			...formData,
			modifiers: [...currentModifiers, ...modifiersToAdd],
		})
	}

	const removeModifier = (idModifier) => {
		const updatedModifiers = currentModifiers.filter((modifier) => modifier != idModifier)
		updatedModifiers.sort((a, b) => (a.name > b.name ? 1 : -1))
		setCurrentModifiers(updatedModifiers)

		// update modifiers on fieldsToSend with currentModifiers
		setFieldsToSend(new Set([...fieldsToSend, 'modifiers']))
		setFormData({
			...formData,
			modifiers: updatedModifiers,
		})
	}

	useEffect(() => {
		let notCurrentModifiers = allModifiers.filter((modifier) => !currentModifiers.includes(modifier.id))
		//order
		notCurrentModifiers.sort((a, b) => (a.name > b.name ? 1 : -1))
		setNotCurrentModifiers(notCurrentModifiers)
		// eslint-disable-next-line
	}, [currentModifiers])

	const handleSelectModifiers = (e) => {
		const selectedModif = Array.from(e.target.selectedOptions).map((opcion) => Number(opcion.value))
		setModifiersToAdd(selectedModif)
	}

	const getModifiersNameById = (modifierId) => {
		const modifier = allModifiers.find((modifier) => modifier.id === modifierId)
		return modifier?.name
	}

	const getModifiersPriceById = (modifierId) => {
		const modifier = allModifiers.find((modifier) => modifier.id === modifierId)
		return modifier?.price
	}

	return (
		<form className="mt-4 px-4 product-form">
			<div className="d-sm-flex align-items-center justify-content-between mb-3 w-100">
				<div className='input_group w-100'>
					<div className="form-group">
						<h6 className="mb-0 mb-2">Id</h6>
						<input
							type="text"
							className="form-control"
							id="productId"
							placeholder="Id"
							defaultValue={formData.id}
							disabled
						/>
					</div>
					<div className="form-group">
						<h6 className="mb-0 mb-2">Status</h6>
						<select onChange={handleInputChange} className="form-control" name="status" required defaultValue={formData.status}>
							<option value="">-</option>
							<option value="active">Activo</option>
							<option value="inactive">Inactivo</option>
						</select>
					</div>
				</div>

				{formData.icon && (
					<div className="d-flex align-items-center justify-content-center mb-2 product-icon-image">
						<div className="d-flex align-items-center justify-content-center">
							<div className="border border-4 border-white d-flex align-items-center justify-content-center overflow-hidden">
								<img src={icon} alt="" className="w-100" />
							</div>
						</div>
					</div>
				)}
			</div>

			<div className="form-group mt-4">
				<h6 className="mb-0 fs-4 mb-2">Nombre</h6>
				<input
					type="text"
					className="form-control"
					id="productName"
					name="name"
					defaultValue={formData.name}
					onChange={handleInputChange}
				/>
			</div>
			<div className="form-group mt-4">
				<h6 className="mb-0 fs-4 mb-2">Descripción</h6>
				<textarea
					className="form-control"
					rows="6"
					id="productDescription"
					name="description"
					defaultValue={formData.description}
					onChange={handleInputChange}
				></textarea>
			</div>
			<div className='input_group'>
				<div className="form-group mt-4">
					<h6 className="mb-0 fs-4 mb-2">Precio</h6>
					<input
						type="number"
						className="form-control"
						id="productPrice"
						name="price"
						defaultValue={formData.price}
						onChange={handleInputChange}
					/>
				</div>
				<div className="form-group mt-4">
					<h6 className="mb-0 fs-4 mb-2">Grupo</h6>
					<select onChange={handleInputChange} className="form-control" name="group" id='group' defaultValue={formData.group}>
						<option value={''}>-</option>
						<option value={'Licores'}>Licores</option>
						<option value={'Carta'}>Carta</option>
						<option value={'Bebidas'}>Bebidas</option>
						<option value={'Merchandising'}>Merchandising</option>
						<option value={'Transfer'}>Transfer</option>
						<option value={'Clases'}>Clases</option>
						<option value={'ticket'}>ticket</option>
					</select>
				</div>
				<div className="form-group mt-4">
					<h6 className="mb-0 fs-4 mb-2">Subgrupo</h6>
					<select onChange={handleInputChange} className="form-control" name="subgroup" id='subgroup' defaultValue={formData.subgroup}>
						<option value={''}>-</option>
						<option disabled value={''}>Licores</option>
						<option value={'Blancos'}>Blancos</option>
						<option value={'Cerveza'}>Cerveza</option>
						<option value={'Sidra'}>Sidra</option>
						<option value={'Rosados'}>Rosados</option>
						<option value={'Vodka'}>Vodka</option>
						<option value={'Tintos'}>Tintos</option>
						<option value={'Cava/Champagne'}>Cava/Champagne</option>
						<option value={'Ginebra'}>Ginebra</option>
						<option value={'Ron'}>Ron</option>
						<option value={'Cognac'}>Cognac</option>
						<option value={'Whisky'}>Whisky</option>
						<option disabled value={''}>Carta</option>
						<option value={'Tapas'}>Tapas</option>
						<option value={'Platos menu'}>Platos menu</option>
						<option value={'Menús'}>Menús</option>
						<option disabled value={''}>Bebidas</option>
						<option value={'Bebidas de Bienvenida'}>Bebidas de Bienvenida</option>
						<option value={'Refrescos'}>Refrescos</option>
						<option value={'Cafe'}>Cafe</option>
						<option disabled value={''}>Clases</option>
						<option value={'Clases Grupales'}>Clases Grupales</option>
						<option value={'Clases Particulares'}>Clases Particulares</option>
						<option disabled value={''}>Merchandising</option>
						<option value={'Servilletero flamenco'}>Servilletero flamenco</option>
						<option value={'Bolas de Navidad'}>Bolas de Navidad</option>
						<option value={'Peinetas hechas a mano'}>Peinetas hechas a mano</option>
						<option value={'Camiseta  Blanca Peineta Lateral'}>Camiseta  Blanca Peineta Lateral</option>
						<option value={'Camiseta Blanca Bailaora Círculo'}>Camiseta Blanca Bailaora Círculo</option>
						<option value={'Camiseta Blanca Guitatta Floral'}>Camiseta Blanca Guitatta Floral</option>
						<option value={'Camiseta Negra Bailaora'}>Camiseta Negra Bailaora</option>
						<option value={'Camiseta Negra Bailaora Con Abanico'}>Camiseta Negra Bailaora Con Abanico</option>
						<option value={'Bolsa Tela Orgánica'}>Bolsa Tela Orgánica</option>
						<option value={'Camiseta Blanca Zapato Niño/a'}>Camiseta Blanca Zapato Niño/a</option>
						<option value={'Camiseta Roja Peineta Frontal'}>Camiseta Roja Peineta Frontal</option>
						<option disabled value={'varios'}>Varios</option>
						<option value={'Transfer Hotel'}>Transfer Hotel</option>
					</select>
				</div>
				
			</div>
			<div className='input_group'>
				<div className="form-group mt-4">
					<h6 className="mb-0 fs-4 mb-2">Type</h6>
					<select onChange={handleInputChange} className="form-control" name="type" defaultValue={formData.type}>
						<option value='' >-</option>
						<option value="pos">Venta en sala</option>
						<option value="ticket">Venta de entradas</option>
						<option value="fnb">Menús en punto de venta</option>
						<option value="other">Otros productos especiales</option>
					</select>
				</div>
				<div className="form-group mt-4">
					<h6 className="mb-0 fs-4 mb-2">Zona</h6>
					<select onChange={handleInputChange} className="form-control" name="zone" defaultValue={formData.zone}>
						<option value="">Sin zona</option>
						<option value="za">Zona A</option>
						<option value="zb">Zona B</option>
						<option value="zc">Zona C</option>
					</select>
				</div>
				<div className="form-group mt-4">
					<h6 className="mb-0 fs-4 mb-2">Categoría</h6>
					<select
						onChange={handleInputChange}
						className="form-control"
						name="category"
						defaultValue={formData.category}
					>
						<option value="">No aplica</option>
						<option value="adult">Adulto</option>
						<option value="children">Infantil</option>
						<option value="dinner">Cenas y menús</option>
						<option value="insurance">Seguros</option>
						<option value="other">Otros</option>
						<option value="mice">MICE</option>
					</select>
				</div>
			</div>
			<div className="form-group mt-4">
				<h6 className="mb-0 fs-4 mb-2">{formData.icon ? 'Cambiar' : 'Añadir'} Icono</h6>
				<input
					type="file"
					onChange={(e) => setProductIcon(e.target)}
					id="productIcon"
					className="form-control form-control-file"
					name="icon"
				/>
			</div>
			<div className="form-group mt-4">
				<h6 className="mb-0 fs-4 mb-2">Modifiers</h6>
				<div className="d-sm-flex mb-3">
					<div className="form-group col-4">
						<p>Todos: </p>
						<select
							multiple
							className="form-control list-group modifiers-list"
							id="all-modifiers-list"
							onChange={handleSelectModifiers}
							value={modifiersToAdd}
						>
							{notCurrentModifiers &&
								notCurrentModifiers.length > 0 &&
								notCurrentModifiers.map((modifier, index) => (
									<option className="list-group-item" key={index} value={modifier.id}>
										{modifier.name} ({modifier.price} €)
									</option>
								))}
						</select>
					</div>
					<div className="form-group mx-4 col-2 text-centered">
						<button
							type="button"
							className="mt-4 btn rmb-btn-secondary text-white"
							id="add-modifier"
							onClick={addModifiers}
						>
							Añadir
							<i className="ti ti-arrow-right fs-5 ms-3"></i>
						</button>
					</div>
					<div className="form-group col-4">
						<p>Añadidos:</p>
						<ul className="list-group modifiers-list">
							{formData.modifiers &&
								formData.modifiers.length > 0 &&
								formData.modifiers.map((modifier, index) => (
									<li className="list-group-item" key={index}>
										<span className="form-check">
											{getModifiersNameById(modifier)} ({getModifiersPriceById(modifier)} €)
											<span className="rmb-bg-danger-light ms-3 remove-icon">
												<i
													className="ti ti-x fs-1"
													onClick={() => removeModifier(modifier)}
													data-value={modifier}
												></i>
											</span>
										</span>
									</li>
								))}
						</ul>
					</div>
				</div>
			</div>

			{	checkPermission('backoffice.product.write') && (
				<button type="button" className="mt-4 btn rmb-btn-primary" onClick={handleSave}>
					Guardar
				</button>
			)}
			<div className="d-flex align-items-right mt-4 fs-2">
				Actualizado: {moment(formData.updatedAt).fromNow()} | Creado: {moment(formData.createdAt).fromNow()}
			</div>

			{responseMessage && (
				<div className="mt-4 alert alert-success" role="alert">
					{responseMessage}
				</div>
			)}
		</form>
	)
}

export default ProductForm
