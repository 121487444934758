import { useState, useEffect, useRef } from "react"
import moment from "moment"
import { checkPermission } from "../utils/backoffice-roles-data"
import { Permissions } from "../components/Permissions"
import { permissions } from "../utils/backoffice-roles-data"

export const BackofficeRoleForm = ({data, sendToUpdate, responseMessage}) => {

    const [formData, setFormData] = useState({
        id: 0,
        name: '',
        permissions: {
            backoffice: false,
        }
    })

    const changedFields = useRef(new Map())

    const handleInputChange = (e) => {

        let {target: {name, value}} = e

        setFormData((prev) => ({...prev, [name]: value}))
        changedFields.current.set(name, '')
    }

    const handlePermissionChange = (path, value) => {

        setFormData(state => {

            // Split the path into keys
            const keys = path.split(".");

            // Create a deep copy of the state
            const newPermissions = { ...state.permissions };

            // Traverse the object to the correct level
            let current = newPermissions;
            for (let i = 0; i < keys.length - 1; i++) {
                const key = keys[i];
                current[key] = { ...current[key] }; // Ensure immutability
                current = current[key];
            }

            // Update the final key
            current[keys[keys.length - 1]] = value;

            // Return the updated state
            return {
                ...state,
                permissions: newPermissions,
            };


        })

    }

    const handleSave = (duplicate = false) => {

        sendToUpdate({
            name: duplicate ? formData.name + ' (copy)' : formData.name,
            ...formData
        }, duplicate)
    }

    const handleDelete = async () => {

        if (formData.id>0) {
            if (window.confirm('¿Estás seguro de que quieres borrar este role?')){
                await sendToUpdate({id: formData.id, venueId:1 ,forceDelete: true})
            }
        }
        window.location.href = '/roles'
    }

    useEffect(() => {

        if (data.id > 0) setFormData(data)

    }, [data])

    return (
        <form className="form role-form" onSubmit={(e) => e.preventDefault()}>
            <div className="input_group">
                <div>
                    <p className="label">Id</p>
                    <input type="text" className="form-control" id="price-rule-id" placeholder="Id" value={formData.id} disabled onChange={()=>{/* prevents the error trigger */}} />
                </div>
                <div>
                    <p className="label">Nombre</p>
                    <input type="text" className="form-control" id="price-rule-name" name="name" defaultValue={formData.name} onChange={handleInputChange} />
                </div>
            </div>
            <div className="permissions-wrapper">
                <Permissions 
                    data={formData.permissions} 
                    permissions={permissions} 
                    updateData={handlePermissionChange} 
                    depth={0}
                />
            </div>
            {    formData.id>0 && ( 
                <div className='update_info'>
                    Actualizado: {moment(formData.updatedAt).fromNow()} | Creado: {moment(formData.createdAt).fromNow()}
                </div>
            )}
            {   checkPermission('backoffice.backofficeRole.write') && (
                <button type="button" className="btn btn-danger mt-3" style={{'float':'right'}} onClick={handleDelete}>
                    {formData.id===0 ? 'Cancelar' : 'Borrar'}
                </button>
            )}
            {   checkPermission('backoffice.backofficeRole.write') && (
                <button type="button" className="btn rmb-btn-primary mt-3" onClick={() => handleSave(false)}>
                    {formData.id===0 ? 'Crear' : 'Guardar'}
                </button>
            )}
            {formData.id!==0 && checkPermission('backoffice.backofficeRole.write') && (
                <button type="button" className="btn rmb-btn-secondary mt-3 ms-3" onClick={() => handleSave(true)}>
                    Duplicar
                </button>
            )}
            {responseMessage && (
                <div className="mt-4 alert alert-success" role="alert">
                    {responseMessage}
                </div>
            )}
        </form>
    )

}