export const columnsRole = [
    {
        name: 'Id',
        cell: (props) => props.id,
        width: '100px'
    },
    {
        name: 'Role',
        cell: (row) => row.name,
        sortable: true,
    },
]

export const permissions = {
    backoffice: {
        booking: {
            view: {
                metrics: false,
                table: false,
                economicData: {
                    originParent1: false,
                    originParent2: false,
                    originParent3: false,
                    originParent13: false,
                    originParent59: false,
                    originParent61: false,
                    originParent69: false,
                    originParent297: false,
                    originParent303: false,
                    originParent5001: false,
                },
                downloadButtons: false,
                salesDensityGraph: false
            },
            write: false,
            filters: {
                allowFilterByDatesPriorTo365days: false, 
                allowFilterByWebOrigins: false
            },
        },
        bookingLine: {
            view: false,
            write: false,
        },
        tracking: {
            view: false,
        },
        cashier: {
            view: {
                table: false,
                totals: false
            },
            filters: {
                allowFilterByAnyUserId: false
            },
        },
        product: {
            view: false,
            write: false,
        },
        payment: {
            view: false,
        },
        priceRule: {
            view: false,
            write: false,
        },
        shift: {
            view: false,
            write: false,
        },
        table: {
            view: false,
            write: false,
        },
        origin: {
            view: false,
            write: false,
        },
        venue: {
            view: false,
            write: false,
        },
        device: {
            view: false,
            write: false,
        },
        log: {
            view: false,
        },
        event: {
            view: false,
            write: false,
        },
        backofficeRole: {
            view: false,
            write: false,
        },
        user: {
            view: false,
            write: false,
        },
        homePage: {
            view: {
                dashboard: false
            }
        }
    },
}

export const getValueByPath = (obj, path) => {

    if (typeof obj !== 'object') throw new Error('obj should be an object')
    if (typeof path !== 'string' || path === '') throw new Error('invalid path, empty string')

    const keys = path.split(".")

    let result = obj
    for (const key of keys) {
        if (result && Object.prototype.hasOwnProperty.call(result, key)) result = result[key]
        else if (result && typeof result === 'boolean'){
            return result
        } else return false
    }

    return JSON.parse(JSON.stringify(result))
}

export const checkPermission = (path) => {

    const user = JSON.parse((localStorage.getItem('user') ?? '{"permissions": {}}'))
    const permissions = user.permissions
    let result = false
    if (path && permissions) result = Boolean(getValueByPath(permissions, path))
    console.log('checkPermission', path, result)

    return result
}