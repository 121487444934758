import useApp from "../utils/useApp"
import { useLocation } from "react-router-dom"
import { Link } from "react-router-dom"
import { checkPermission } from "../utils/backoffice-roles-data"
import { PAGES } from "../utils/utils"

const MenuLink = ({href = '/', text = '', icon = '', roles = [], noRoles = [], ...rest}) => {

	const location = useLocation()
	
	return (
		<li className={`sidebar-item ${location.pathname === href ? 'selected' : ''}`} {...rest}>
			<Link className="sidebar-link" to={href} aria-expanded="false">
				<span>
					<i className={`ti ${icon}`}></i>
				</span>
				<span className="hide-menu">{text}</span>
			</Link>
		</li>
	)
}
const MenuSeparator = ({text = '', hrefs = []}) => {

	const {state: { user }} = useApp()

	const pages = hrefs.map(href => {

		const page = PAGES.find(page => page.href === href)
		const userCanViewPage = checkPermission(`backoffice.${page.model}.view`)

		if (page && userCanViewPage) return page
		else return false
	}).filter(value => value)

	return pages.length ? (
		<>
		<li className="nav-small-cap">
			<i className={`ti ti-dots nav-small-cap-icon fs-4`}></i>
			<span className="separator-text">{text}</span>
		</li>
		{pages.map((page, index) => <MenuLink key={index} href={page.href} text={page.name} icon={page.icon} />)}
		</>
	) : null
}

const AsideComponent = () => {

	const {state: { user }} = useApp()

	return (
		<aside className="left-sidebar">
			{
				//  Sidebar scroll
			}
			<div>
				<div className="brand-logo d-flex align-items-center justify-content-between">
					<a href="./index.html" className="text-nowrap logo-img">
						<img src="/images/rumba.png" className="dark-logo" width="38" height="38" alt="Logo" />
						<img
							src="/images/rumba.svg"
							className="dark-logo"
							width="60"
							alt="Rumba"
							style={{ marginLeft: 12 }}
						/>
					</a>
					<div className="close-btn d-lg-none d-block sidebartoggler cursor-pointer" id="sidebarCollapse">
						<i className="ti ti-x fs-8"></i>
					</div>
				</div>
				<nav className="sidebar-nav scroll-sidebar" data-simplebar>
					<ul id="sidebarnav">
						{/* Reservas */}
						<MenuLink href="/" text="inicio" icon="ti-home" roles={['admin']} />
						<MenuSeparator text="Reservas" hrefs={[
							"/sales",
							"/bookings-lines",
							"/events",
						]}/>
						<MenuSeparator text="Admin" hrefs={[
							"/roles",
							"/users",
							"/trackings",
							"/payments",
							"/price-rules",
						]}/>
						<MenuSeparator text="Configuración" hrefs={[
							"/cashiers",
							"/products",
							"/shifts",
							"/tables",
							"/origins",
							"/venues",
						]}/>
						<MenuSeparator text="Developer" hrefs={[
							"/devices",
							"/logs",
						]}/>
					</ul>
					<div className=""></div>
				</nav>
				<div className="fixed-profile p-3 bg-light-secondary rounded sidebar-ad mt-3">
					<div className="hstack gap-3">
						<div className="john-img">
							<img
								src="/images/profile/user-1.jpg"
								className="rounded-circle"
								width="40"
								height="40"
								alt=""
							/>
						</div>
						<div className="john-title">
							<h6 className="mb-0 fs-4 fw-semibold">Mathew</h6>
							<span className="fs-2 text-dark">Designer</span>
						</div>
						<button
							className="border-0 bg-transparent text-primary ms-auto"
							tabIndex="0"
							type="button"
							aria-label="logout"
							data-bs-toggle="tooltip"
							data-bs-placement="top"
							data-bs-title="logout"
						>
							<i className="ti ti-power fs-6"></i>
						</button>
					</div>
				</div>
				{
					//  End Sidebar navigation
				}
			</div>
			{
				//  End Sidebar scroll
			}
		</aside>
	)
}

export default AsideComponent
