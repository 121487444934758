import { useEffect, useState } from 'react'
import moment from 'moment'
import 'moment/locale/es'
import { getDataApi } from '../services/apiService'
import { checkPermission } from '../utils/backoffice-roles-data'
import useApp from '../utils/useApp'
moment.locale('es')

const DeviceForm = ({ data, sendToUpdate }) => {
	const {
		state: { user },
	} = useApp()
	const [formData, setFormData] = useState({})
	const [fieldsToSend, setFieldsToSend] = useState([])
	const [origins, setOrigins] = useState([])

	useEffect(() => {
		getOriginsData()
		setFormData(data)
	}, [data])

	const getOriginsData = async () => {
		const result = await getDataApi(user.lastVenue, 'origin', 0, 1000)
		const originDataResponse = Object.values(result.data).filter((origin) => origin.parent === 2)
		setOrigins(originDataResponse)
	}

	const handleInputChange = (e) => {
		let { name, value } = e.target
		if (name === 'origin') {
			name = 'location'
			const pointName = origins.find((origin) => origin.id === parseInt(value)).name
			value = { id: value, location: 'Punto de venta - ' + pointName }
		}
		setFieldsToSend(new Set([...fieldsToSend, name]))
		setFormData({
			...formData,
			[name]: value,
		})
	}

	const handleSave = () => {
		// console.log('fieldsToSend', fieldsToSend)
		if (fieldsToSend.size > 0) {
			const dataToSend = Object.keys(formData)
				.filter((key) => fieldsToSend.has(key))
				.reduce((obj, key) => {
					obj[key] = formData[key]
					return obj
				}, {})

			//console.log('dataToSend', dataToSend)
			sendToUpdate(dataToSend)
		}
		window.location.href = '/devices'
	}

	return (
		<form className="mt-4 px-4">
			<div className="d-sm-flex align-items-center justify-content-between mb-3">
				<div>
					<h6 className="mb-0 fs-4 mb-2">Id</h6>
					<div className="form-group">
						<input
							type="text"
							className="form-control"
							id="deviceId"
							placeholder="Id"
							defaultValue={formData.id}
							disabled
						/>
					</div>
				</div>
				<div>
					<h6 className="mb-0 fs-4 mb-2">Estado</h6>
					<div className="form-check form-check-inline">
						<input
							className="form-check-input success"
							type="radio"
							id="activeStatus"
							checked={formData.status === 'active'}
							value="active"
							onChange={handleInputChange}
							name="status"
						/>
						<label className="form-check-label" htmlFor="activeStatus">
							Active
						</label>
					</div>
					<div className="form-check form-check-inline">
						<input
							className="form-check-input danger"
							type="radio"
							id="inactiveStatus"
							checked={formData.status === 'inactive'}
							value="inactive"
							onChange={handleInputChange}
							name="status"
						/>
						<label className="form-check-label" htmlFor="inactiveStatus">
							Inactive
						</label>
					</div>
				</div>
			</div>

			<div className="form-group mt-4">
				<h6 className="mb-0 fs-4 mb-2">Nombre</h6>
				<input
					type="text"
					className="form-control"
					name="name"
					id="deviceName"
					defaultValue={formData.name}
					onChange={handleInputChange}
				/>
			</div>
			<div className="form-group mt-4">
				<h6 className="mb-0 fs-4 mb-2">Versión</h6>
				<input
					type="text"
					className="form-control"
					name="version"
					id="deviceVersion"
					defaultValue={formData.version}
					onChange={handleInputChange}
				/>
			</div>
			<div className="form-group mt-4">
				<h6 className="mb-0 fs-4 mb-2">Identificador</h6>
				<input
					type="text"
					className="form-control"
					name="identificator"
					id="deviceIdentif"
					defaultValue={formData.identificator}
					onChange={handleInputChange}
				/>
			</div>
			<div className="form-group mt-4">
				<h6 className="mb-0 fs-4 mb-2">Localización</h6>
				<select
					className="form-select"
					name="origin"
					value={formData?.location?.id}
					onChange={handleInputChange}
				>
					<option value={0}>Sin origen</option>
					{origins &&
						origins.map((origin) => (
							<option key={origin.id} value={origin.id}>
								{origin.name}
							</option>
						))}
				</select>
				<br />
				<textarea
					className="form-control"
					disabled
					rows="6"
					name="location"
					id="deviceLocation"
					defaultValue={formData.location && JSON.stringify(formData.location, null, 2)}
					onChange={handleInputChange}
				></textarea>
			</div>
			<div className="d-flex align-items-right fs-2 mt-4">
				Actualizado: {moment(formData.updatedAt).fromNow()} | Creado: {moment(formData.createdAt).fromNow()}
			</div>
			{ checkPermission(`backoffice.device.write`) && (

				<button type="button" className="mt-4 btn rmb-btn-primary" onClick={handleSave}>
					Guardar
				</button>
			)}
		</form>
	)
}

export default DeviceForm
