import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const columnsUsers = [
    {
        name: 'id',
        selector: row => row.id,
        sortable: true,
        width: '50px'
    },
    {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
    },
    {
        name: 'Role',
        selector: row => row.role,
        sortable: true,
        width: '130px'
    },
    {
        name: 'Email',
        selector: row => row.email,
        width: '130px'
    },
    // {
    //     name: 'Image',
    //     selector: row => row.image,
    //     cell: props => (
    //         <img
    //           src={props.image ? props.image : "https://rumba.cardamomo.com/img/neutral.png"}
    //           width={80}
    //           className="icon-image"
    //           alt='user'
    //         />
    //       )
    // },
    {
        name: 'Status',
        selector: row => row.status,
        cell: props => {
            if (props.status === 'active') {
                return <span className="badge bg-light-success text-success">{props.status}</span>
            } else {
                return <span className="badge bg-light-danger text-danger">{props.status}</span>
            }
        },
        sortable: true,
        width: '100px'
    },
    {
        name: 'Last Login',
        selector: row => row.lastLogin,
        sortable: true,
        cell: props => props.lastLogin ? moment(props.lastLogin).fromNow() : 'never',
        width: '100px',
    },
    {
        name: 'Created At',
        selector: row => row.createdAt,
        sortable: true,
        cell: props => moment(props.createdAt).fromNow(),
        width: '100px'
    },
    {
        name: 'Updated At',
        selector: row => row.updatedAt,
        sortable: true,
        cell: props => {return moment(props.updatedAt).fromNow()},
        width: '100px'
    },
    {
        name: 'Last Venue',
        selector: row => row.lastVenue,
        sortable: true,
        width: '80px'
    },
]


const dataUsers = [
    {
        id: 1,
        name: 'User 1',
        role: 'Admin',
        email: 'email@mail.es',
        image: 'image.png',
        status: 'Active',
        lastLogin: '2021-01-01',
        createdAt: '2021-01-01',
        updatedAt: '2021-01-01',
        pin: '1234',
        lastVenue: '1',
        token: '123456789'
    },
    {
        id: 2,
        name: 'User 2',
        role: 'Admin',
        email: 'email@mail.es',
        image: 'image.png',
        status: 'Active',
        lastLogin: '2021-01-01',
        createdAt: '2021-01-01',
        updatedAt: '2021-01-01',
        pin: '1234',
        lastVenue: '2',
        token: '123456789'
    }
]

export { columnsUsers, dataUsers }


